import { forwardRef, useEffect, useState } from 'react';

import { useNavigate } from "react-router-dom";

// components:
import CarouselItem from '../CarouselItem/CarouselItem';
import SliderIndicators from '../SliderIndicators/SliderIndicators';

// data:
import { caseStudyPath } from '../../navigatePath';

// svg:
import arrow from '../../svg/header_arrow.svg';

// img:
import human from '../../img/header_human.png';
import rock from '../../img/rock.png';

import './Header.scss';

const Header = forwardRef((props, ref) => {
  const [activeSlide, setAciveSlide] = useState(0);
  const [activeCircle, setAciveCircle] = useState(0);

  useEffect(() => {
    const elementsForAnimation = [...document.querySelectorAll('.active .animation')];
    elementsForAnimation.map(item => item.classList.remove('active'));
  }, [activeSlide]);

  const navigate = useNavigate();
  const handleGoTocaseStudy = () => navigate(caseStudyPath);

  // slider:
  const slidersArr = [0, 1, 2, 3];

  useEffect(() => {
    setTimeout(() => handleSliderIndicators(activeSlide + 1 === slidersArr.length ? 0 : activeSlide + 1), 10000)
  }, [activeSlide])

  const handleSliderIndicators = (e) => {
    const elementsForAnimation = [...document.querySelectorAll('.active .animation')];
    elementsForAnimation.map(item => item.classList.add('active'));
    const currentTarget = e?.currentTarget?.getAttribute('data-id') || e;
    const changeActiveElement = (setElem) => {
      if (currentTarget === 'next') setElem(prevElem => {
        if (prevElem === slidersArr.length - 1) return 0;
        else return prevElem + 1;
      })
      else setElem(parseInt(currentTarget));
    };
    changeActiveElement(setAciveCircle);

    setTimeout(() => changeActiveElement(setAciveSlide), 3500)
  };

  const renderCarouselItem = slidersArr
    .map(slide => {
      if (slide === activeSlide) return (<CarouselItem
        key={slide}
        id={slide}
        className={'active'}
        handleGoTocaseStudy={handleGoTocaseStudy}
        handleSliderIndicators={handleSliderIndicators}
        human={human}
        rock={rock}
      />);
      else return null;
      // else return (CarouselItem(slide, ''));
    });

  const renderCircles = slidersArr
    .map(item => <SliderIndicators
      key={item}
      id={item}
      className={item === activeCircle ? 'active' : ''}
      onClick={handleSliderIndicators} />);

  // scroll to bottom ('Scroll down' button):
  const goToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <header className="header" ref={ref}>
      {renderCarouselItem}
      <div className="carouselItem__wrap-human animation">
        <img src={human} className="carouselItem__human" alt="human" />
      </div>
      <div className="header__wrap-circles" >
        {renderCircles}
      </div>
      <button className="header__aside-btn" onClick={goToBottom}>
        <p className="header__scroll">Scroll down</p>
        <div className="header__wrap-arrow">
          <img src={arrow} className="header__arrow" alt="arrow" />
        </div>
      </button>
    </header>
  );
})

export default Header;