// svg:
import playIconActive from '../../svg/last_in_play_icon_active.svg';

import './ArrowButtonSlider.scss';

const ArrowButtonSlider = ({ onClick, side, isDisabled }) => {
    return (
        <button
            onClick={onClick}
            data-side={side}
            className="arrowButtonSlider__play-icon"
            disabled={isDisabled}>
            <img src={playIconActive} alt="play icon" />
        </button>
    );
}

export default ArrowButtonSlider;
