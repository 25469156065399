import { useEffect, useState, useLayoutEffect } from 'react';

import { media } from './../theme/media';

const useMedia = () => {
  const [isMobile, setIsMobile] = useState(0);
  const [isTablet, setIsTablet] = useState(0);
  const [isDesktop, setIsDesktop] = useState(0);
  const [exceptMobile, setExceptMobile] = useState(0);
  const [exceptTablet, setExceptTablet] = useState(0);
  const [exceptDesktop, setExceptDesktop] = useState(0);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const handleWindowResize = () => {
    setIsMobile(window.matchMedia(media.onlyMobile).matches);
    setIsTablet(window.matchMedia(media.onlyTablet).matches);
    setIsDesktop(window.matchMedia(media.onlyDesktop).matches);
    setExceptMobile(window.matchMedia(media.exceptMobile).matches);
    setExceptTablet(window.matchMedia(media.exceptTablet).matches);
    setExceptDesktop(window.matchMedia(media.exceptDesktop).matches);
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useLayoutEffect(() => {
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize, { passive: true });
    return () => window.removeEventListener("resize", handleWindowResize, { passive: true });
  });

  return {
    isMobile: isMobile,
    isTablet: isTablet,
    isDesktop: isDesktop,
    exceptMobile: exceptMobile,
    exceptTablet: exceptTablet,
    exceptDesktop: exceptDesktop,
    windowWidth: width,
    windowHeight: height
  };
}

export default useMedia;
