import { useLayoutEffect, useState } from 'react';

// section:
import HeaderBlack from '../../components/HeaderBlack/HeaderBlack';
import ARProjects from './ARProjects/ARProjects';
import VRProjects from './VRProjects/VRProjects';
import MRProjects from './MRProjects/MRProjects';

// components:
import Line from '../../components/Line/Line';

// data:
import { txtForNavInOurCaseStudyList } from '../../constants';

import './OurCaseStudyList.scss';

const OurCaseStudyList = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {

    const [main, setMain] = useState('Augmented Reality');

    useLayoutEffect(() => {
        setDarkLogoIcon(false);
        setDarkMenuIcon(false);
        setDisplayFoter(true);
    }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter]);

    const handleNavClick = (e) => setMain(e.currentTarget.dataset.name);

    const renderMainComponent = () => {
        if (main === 'Augmented Reality') { return <ARProjects /> }
        else if (main === 'Virtual Reality') { return <VRProjects /> }
        else if (main === 'Mixed Reality') { return <MRProjects /> }
    };

    return (
        <div className="ourCaseStudyList">
            <HeaderBlack
                title={'Our case study'}
                span={'is an innovative '}
                txt={'product team made up of experienced'}
                nav={true}
                navTxt={txtForNavInOurCaseStudyList}
                handleNavClick={handleNavClick}
            />
            <main className="ourCaseStudyList__main">
                {renderMainComponent()}
                <Line
                    className={'ourCaseStudyList'} />
                <Line
                    className={'ourCaseStudyList'} />
            </main>
        </div>
    );
}

export default OurCaseStudyList;
