import { useState, useEffect, useRef, useContext } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from '../../AppContext';

import './Cursor.scss';

const Cursor = ({ displayFooter }) => {
    const cursorDotOutline = useRef();
    const cursorDot = useRef();
    const requestRef = useRef();
    const previousTimeRef = useRef();
    const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);
    const cursorVisible = useState(false);
    const cursorEnlarged = useState(false);
    const cursorPlayIcon = useState(false);

    const dataVR = useContext(AppContext).dataVR;
    const dataAR = useContext(AppContext).dataAR;
    // const dataMR = useContext(AppContext).dataMR;

    const { pathname } = useLocation();

    /**
     * Mouse Moves
     */
    const onMouseMove = event => {
        const { pageX: x, pageY: y } = event;
        setMousePosition({ x, y });
        positionDot(event);
    };
    const onMouseEnter = () => {
        cursorVisible.current = true;
        toggleCursorVisibility();
    };
    const onMouseLeave = () => {
        cursorVisible.current = false;
        toggleCursorVisibility();
    };
    const onMouseDown = () => {
        cursorEnlarged.current = true;
        toggleCursorSize();
    };
    const onMouseUp = () => {
        cursorEnlarged.current = false;
        toggleCursorSize();
    };
    const onResize = event => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    /**
     * Hooks
     */
    useEffect(() => {
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseenter", onMouseEnter);
        document.addEventListener("mouseleave", onMouseLeave);
        document.addEventListener("mousedown", onMouseDown);
        document.addEventListener("mouseup", onMouseUp);
        window.addEventListener("resize", onResize);
        requestRef.current = requestAnimationFrame(animateDotOutline);

        handleLinksAndButtons();
        handleRectangle();

        return () => {
            document.removeEventListener("mousemove", onMouseMove);
            document.removeEventListener("mouseenter", onMouseEnter);
            document.removeEventListener("mouseleave", onMouseLeave);
            document.removeEventListener("mousedown", onMouseDown);
            document.removeEventListener("mouseup", onMouseUp);
            window.removeEventListener("resize", onResize);
            cancelAnimationFrame(requestRef.current);
        };
    }, [pathname, displayFooter, dataAR, dataVR]);

    let { x, y } = mousePosition;
    const winDimensions = { width, height };
    let endX = winDimensions.width / 2;
    let endY = winDimensions.height / 2;

    /**
     * Position Dot (cursor)
     * @param {event}
     */
    function positionDot(e) {
        cursorVisible.current = true;
        toggleCursorVisibility();
        // Position the dot
        endX = e.pageX;
        endY = e.pageY;
        cursorDot.current.style.top = endY + "px";
        cursorDot.current.style.left = endX + "px";
    }

    /**
     * Toggle Cursor Visiblity
     */
    const toggleCursorVisibility = () => {
        if (cursorVisible.current) {
            cursorDot.current.style.opacity = 0.6;
            cursorDotOutline.current.style.opacity = 0.6;
        } else {
            cursorDot.current.style.opacity = 0;
            cursorDotOutline.current.style.opacity = 0;
        }
    }

    /**
     * Toggle Cursor Size
     */
    const toggleCursorSize = () => {
        if (cursorDot.current === null || cursorDotOutline.current === null) return;
        if (cursorEnlarged.current) {
            cursorDot.current.style.transform = "translate(-50%, -50%) scale(0.7)";
            cursorDotOutline.current.style.transform =
                "translate(-50%, -50%) scale(2)";
        } else {
            cursorDot.current.style.transform = "translate(-50%, -50%) scale(1)";
            cursorDotOutline.current.style.transform =
                "translate(-50%, -50%) scale(1)";
        }
    };

    const setCursorPlayIcon = () => {
        if (cursorDot.current === null || cursorDotOutline.current === null) return;
        if (cursorPlayIcon.current) {
            cursorDot.current.classList.add('play');
            cursorDotOutline.current.classList.add('play');
        } else {
            cursorDot.current.classList.remove('play');
            cursorDotOutline.current.classList.remove('play');
        }
    };

    /**
     * Handle Links and Buttons
     * Applies mouseover/out hooks on all links and buttons
     * to trigger cursor animation
     */
    const handleLinksAndButtons = () => {
        document.querySelectorAll('a, button').forEach(item => {
            item.addEventListener("mouseover", () => {
                cursorEnlarged.current = true;
                toggleCursorSize();
            });
            item.addEventListener("mouseout", () => {
                cursorEnlarged.current = false;
                toggleCursorSize();
            });
        });
    };

    const handleRectangle = () => {
        document.querySelectorAll('.ourCasestudy__rectangle').forEach(item => {
            item.addEventListener("mouseover", () => {
                cursorPlayIcon.current = true;
                setCursorPlayIcon();
            });
            item.addEventListener("mouseout", () => {
                cursorPlayIcon.current = false;
                setCursorPlayIcon();
            });
        });
    };

    /**
     * Animate Dot Outline
     * Aniamtes cursor outline with trailing effect.
     * @param {number} time
     */
    const animateDotOutline = time => {
        if (previousTimeRef.current !== undefined) {
            x += (endX - x) / 8;
            y += (endY - y) / 8;
            cursorDotOutline.current.style.top = y + "px";
            cursorDotOutline.current.style.left = x + "px";
        }
        previousTimeRef.current = time;
        requestRef.current = requestAnimationFrame(animateDotOutline);
    };

    return (
        <>
            <div ref={cursorDotOutline} id="cursor-dot-outline" />
            <div ref={cursorDot} id="cursor-dot" />
        </>
    );
}

export default Cursor;
