import { forwardRef, useState } from 'react';
import { useNavigate } from "react-router-dom";

// data:
import { txtForOurService } from '../../../../constants';
import { aboutUsPath } from '../../../../navigatePath';

// components:
import Button from '../../../../components/Button/Button';


import './OurService.scss';

const OurService = forwardRef((props, ref) => {

  const navigate = useNavigate();
  const handleNavigateTo = () => navigate(aboutUsPath);
  const [activePanelIndex, setActivePanelIndex] = useState(0);

  const renderService = txtForOurService
    .map((service, index) =>
      <div
        key={index}
        className={`OurService__service`}
        // className={`OurService__service ${activePanelIndex === index ? 'active' : ''}`}
        onClick={() => setActivePanelIndex(index)}
      >
        <p className='OurService__p'>{service.name}</p>
        {/* <p className='OurService__info'>+ Quick information about the role</p> */}
      </div>);

  const renderSlides = txtForOurService
    .map((slide, index) =>
      <div
        className={`OurService__slide ${activePanelIndex === index ? 'active' : ''}`}
        key={index}
        style={{ transform: `translateX(${index * 100}%)`, padding: '100px 6.04% 0', height: '100%', width: '100%' }}
      >
        <p className='OurService__we-create'><span className='OurService__bold'>{slide.boldedStart} </span><span className='OurService__underline'>{slide.underlinedMiddle}</span> {slide.endPara}</p>
        <p className='OurService__p' dangerouslySetInnerHTML={{ __html: slide.followUp }}></p>
        <Button txt={"Find out more"} onClick={() => navigate(slide.buttonLink)} />
      </div>);

  return (
    <section className='OurService' ref={ref}>
      <div className='OurService__wrap'>
        <header className='OurService__header'>
          <h2 className='OurService__h2'>What we do?</h2>
          <h1 className='OurService__h1'>our services</h1>
          <p className='OurService__txt'><span className='OurService__span'>Embrace</span> limitless possibilities with our services.</p>
        </header>
        <div className='OurService__service-wrap'>
          {renderService}
        </div>
      </div>
      <div className='OurService__wrap slides_initial'>
        <div>
          <div className='OurService__slides' style={{ transform: `translateX(-${(activePanelIndex) * 100}%)` }}>
            {/* <div className='OurService__slide active'>
          <p className='OurService__we-create'><span className='OurService__bold'>We create </span><span className='OurService__underline'>strategies, set roadmaps, design solutions,</span> create and implement products. From concept to implementation, from strategic to tactical.</p>
          <p className='OurService__p'>Not only for those who are <span className='OurService__blue'>curious</span> First of all, for those who are <span className='OurService__blue'>ready!</span>
          </p>
          <Button txt={"Come on, let's tell you more"} onClick={handleNavigateTo} />
        </div> */}
            {renderSlides}
          </div>
        </div>
      </div>
    </section>
  );
})

export default OurService;
