import { Link } from 'react-router-dom';

// data:
import { txtForMenuLinks } from '../../constants';

// svg:
import logo_dark from '../../svg/logo_icon_dark.svg';

// components:
import Logo from '../Logo/Logo';
import LetsTalk from '../LetsTalk/LetsTalk';

import Navbar from 'react-bootstrap/Navbar';

import './Footer.scss';

const Footer = () => {

    const renderLinks = txtForMenuLinks
        .map(link =>
            <li key={link.txt} className="footer__li">
                <Link className="footer__a" to={link.path}>{link.txt}</Link>
            </li>);

    return (
        <footer className="footer">
            <LetsTalk />
            <div className="footer__wrap">
                <Navbar.Brand href="#">
                    <Logo className={'footer'} svg={logo_dark} />
                </Navbar.Brand>
                <menu className="footer__menu">
                    <ul className="footer__ul">
                        {renderLinks}
                    </ul>
                </menu>
            </div>

        </footer>
    );
}

export default Footer;