import { useState, useLayoutEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

// function:
import { changeOverflowForRootElem } from '../../function';
import useMedia from '../../hooks/useMedia';

// data:
import { txtForMenuLinks, txtForSocialMedia } from '../../constants';
import { contactPath } from '../../navigatePath';

// svg:
import arrow from '../../svg/menu_arrow.svg';
import menu_FFF from '../../svg/menu_icon_white.svg';
import menu_dark from '../../svg/menu_icon_dark.svg';
import logo_dark from '../../svg/logo_icon_dark.svg';
import logo_FFF from '../../svg/logo_icon_FFF.svg';

// components:
import Button from '../Button/Button';
import Cursor from "../Cursor/Cursor";
import Logo from '../Logo/Logo';

// react-bootstrap:
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';

import './Menu.scss';

const Menu = ({ darkLogoIcon, darkMenuIcon }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isMobile } = useMedia();

  const [expanded, setExpanded] = useState(false);

  const DELAY_CLOSE_MENU = '1.2s';

  useLayoutEffect(() => {
    changeOverflowForRootElem(expanded/* , contactPath */, pathname);
  }, [expanded, pathname]);

  const handleCloseMenu = () => {
    document.querySelector('.offcanvas.offcanvas-top').style.transitionDelay = DELAY_CLOSE_MENU;
    document.querySelectorAll('.menu-animation').forEach(item => item.classList.add('active'));
    setExpanded(false);
  }

  const renderMenuLinks = (animationDelayForStart) => {
    let animationDelay = animationDelayForStart;
    return txtForMenuLinks
      .map(link => {
        animationDelay += 0.1;
        return <Link onClick={handleCloseMenu} className='nav-link menu-animation' key={link.txt} to={link.path} style={{ animationDelay: `${animationDelay}s` }}>{link.txt}</Link>
      });
  }

  const renderSocialMediaLinks =
    txtForSocialMedia
      // .map(link => <Nav.Link onClick={handleCloseMenu} className='menu-animation' key={link.name} href="#action1">{link.name}</Nav.Link>);
      .map(link => <Nav.Link onClick={handleCloseMenu} className='menu-animation' key={link.name} href={link.href}>
        <img className="menu__icon" src={link.icon} alt={`${link.name} icon`} />
        <p className="menu__p" href={link.href}>{link.name}</p>
      </Nav.Link>);

  //     <a href="#action1" className="letsTalk__a">
  //     <img className="letsTalk__icon" src={link.icon} alt={`${link.name} icon`} />
  //     <p className="letsTalk__p" href="#action1">{link.name}</p>
  // </a>

  const renderLogoColor = darkLogoIcon ? logo_dark : logo_FFF;
  const renderMenuColor = darkMenuIcon ? menu_dark : menu_FFF;
  const renderMenuColorTxt = darkMenuIcon ? 'menu__menu dark' : 'menu__menu';

  const handleGoToEstimate = () => {
    navigate(contactPath);
    handleCloseMenu();
  };

  return (
    <div className="menu">
      <Navbar className="flex-column" expand={false} bg="transparent" variant="dark" expanded={expanded}>
        <Container fluid>
          <Navbar.Brand href="/">
            <Logo className={'menu'} svg={renderLogoColor} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="offcanvasNavbar" onClick={() => setExpanded(expanded ? false : true)}>
            <div className="menu__wrap-menu" aria-controls="offcanvasNavbar">
              <p className={renderMenuColorTxt}>Menu</p>
              <img src={renderMenuColor} className="menu__menu-svg" alt="menu" />
            </div>
          </Navbar.Toggle >
          <Navbar.Offcanvas
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
            placement="top"
          >
            <Cursor />
            <Offcanvas.Header closeButton onClick={handleCloseMenu}>
              <Navbar.Brand href="#">
                <Logo className={'menu'} svg={logo_FFF} />
              </Navbar.Brand>
            </Offcanvas.Header>
            <Offcanvas.Body >
              <Nav className="flex-grow-1">
                <ul>
                  {renderMenuLinks(0.3)}
                </ul>
              </Nav>
              <div className="menu__social-media-wrap">
                <p className="menu__social-media-txt menu-animation">Social media</p>
                <Nav className="menu__social-media-wrap-links">
                  {renderSocialMediaLinks}
                </Nav>
                <Button
                  txt={'Estimate a project'}
                  svg={arrow}
                  onClick={handleGoToEstimate}
                  className={'menu-animation'} />
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </div>
  );
}

export default Menu;
