// data:
import { txtForHeaderH1, txtForHeaderParagraph } from '../../constants';
import { carouselItems } from '../../utils/data/indexHeader';

import { useNavigate } from "react-router-dom";

// components:
import Button from '../Button/Button';

import './CarouselItem.scss';

const CarouselItem = (props) => {
  const navigate = useNavigate();
  const txtArrayDivide = (txtArray, chunkSize, txtArrayInPieces) => {
    for (let i = 0; i < txtArray.length; i += chunkSize) {
      const chunk = txtArray.slice(i, i + chunkSize);
      txtArrayInPieces.push(chunk);
    }
  };

  // functions for h1:
  const txtForHeaderH1Array = carouselItems[props.id].h1;
  const txtForHeaderH1ArrayInPieces = [...txtForHeaderH1Array];
  const chunkSizeH1 = txtForHeaderH1Array.length / 2;

  // txtArrayDivide(txtForHeaderH1Array, chunkSizeH1, txtForHeaderH1ArrayInPieces);

  const renderHeaderH1 = (partOfArray, animationDelayForStart) => {
    let animationDelay = animationDelayForStart;
    return txtForHeaderH1ArrayInPieces[partOfArray]
      .split(" ")
      .map(word => {
        animationDelay += 0.1;
        return (<span key={word} className="carouselItem__h1-span animation" style={{ animationDelay: `${animationDelay}s` }}>{`${word} `}</span>);
      });
  };

  // functions for Paragraph (p):
  const txtForHeaderParagraphArray = carouselItems[props.id].para.split(" ");
  const txtForHeaderParagraphArrayInPieces = [];
  const chunkSizeParagraph = 10;

  txtArrayDivide(txtForHeaderParagraphArray, chunkSizeParagraph, txtForHeaderParagraphArrayInPieces);

  const renderHeaderParagraph = (partOfArray, animationDelayForStart) => {
    let animationDelay = animationDelayForStart;
    return txtForHeaderParagraphArrayInPieces[partOfArray]
      .map(word => {
        animationDelay += 0.04;
        return (<p key={word} className="carouselItem__p animation" style={{ animationDelay: `${animationDelay}s` }}>{`${word} `}</p>);
      });
  };


  return (
    <div id={props.id} className={`carouselItem ${props.className}`}>
      <div className="carouselItem__wrap-txt">
        <h2 className={`carouselItem__h2 animation`}><span className="carouselItem__h2 span">{carouselItems[props.id].h2.category}</span>{` | `}{carouselItems[props.id].h2.text}</h2>
        <h1 className="carouselItem__h1">
          {renderHeaderH1(0, 0.9)}
        </h1>
        <h1 className="carouselItem__h1">
          {renderHeaderH1(1, 1.22)}
        </h1>
        <div className="carouselItem__wrap-p">
          {renderHeaderParagraph(0, 1.25)}
        </div>
        <div className="carouselItem__wrap-p">
          {renderHeaderParagraph(1, 1.75)}
        </div>
        <Button txt={carouselItems[props.id].link.text} onClick={() => navigate(carouselItems[props.id].link.href)} className={'animation'} />
      </div>
      <div className="carouselItem__bgc-right animation"></div>
      <div className="carouselItem__wrap-ellipse animation">
      </div>
      <button className="carouselItem__next" data-id='next' onClick={props.handleSliderIndicators}>
        <span className="carouselItem__span animation">next</span><span className="carouselItem__span animation">project</span>
      </button>
      {/* <div className="carouselItem__wrap-human animation">
        <img src={props.human} className="carouselItem__human" alt="human" />
      </div> */}
      <div className="carouselItem__wrap-rock animation">
        <img src={props.rock} className="carouselItem__rock" alt="rock" />
      </div>
    </div>
  );
};

export default CarouselItem;
