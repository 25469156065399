import { useEffect, useLayoutEffect, useRef, useState } from 'react';

// section:
import HeaderBlack from '../../components/HeaderBlack/HeaderBlack';
import WhoWeAre from './WhoWeAre/WhoWeAre';
import HowWeWork from './HowWeWork/HowWeWork';
import OurClients from './OurClients/OurClients';
import OurTechnology from './OurTechnology/OurTechnology';

import './AboutUs.scss';



const AboutUs = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {

    const [heightForHeaderBlack, setHeightForHeaderBlack] = useState(0);
    const [heightForWhoWeAre, setHeightForWhoWeAre] = useState(0);
    const [heightForHowWeWork, setHeightForHowWeWork] = useState(0);
    const [heightForOurClients, setHeightForOurClients] = useState(0);
    const [heightForOurTechnology, setHeightForOurTechnology] = useState(0);

    const refHeaderBlack = useRef(null);
    const refWhoWeAre = useRef(null);
    const refHowWeWork = useRef(null);
    const refOurClients = useRef(null);
    const refOurTechnology = useRef(null);

    useLayoutEffect(() => {
        setDarkLogoIcon(false);
        setDarkMenuIcon(false);
        setDisplayFoter(true);
    }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter]);

    useEffect(() => {

        // sectionHeight:
        const section1 = refHeaderBlack.current.clientHeight;
        const section2 = refWhoWeAre.current.clientHeight;
        const section3 = refHowWeWork.current.clientHeight;
        const section4 = refOurClients.current?.clientHeight || 0;
        // const section5 = refOurTechnology.current.clientHeight;

        // setHeightForHeaderBlack(section1);
        // setHeightForWhoWeAre(section1 + section2);
        // setHeightForHowWeWork(section1 + section2 + section3);
        // setHeightForOurClients(section1 + section2 + section3 + section4);
        setHeightForOurTechnology(section1 + section2 + section3 + section4);
    }, []);

    return (
        <div className="aboutUs">
            <HeaderBlack
                title={'About us'}
                span={'innovative '}
                txt={'product team made up of experts'}
                nav={false}
                ref={refHeaderBlack} />
            <WhoWeAre ref={refWhoWeAre} />
            <HowWeWork ref={refHowWeWork} />
            {/* <OurClients ref={refOurClients} /> */}
            <OurTechnology height={heightForOurTechnology} ref={refOurTechnology} />
        </div>
    );
}

export default AboutUs;
