import './ArticleDate.scss';

const ArticleDate = ({ service, date, place }) => {

    return (
        <p className={`articleDate__info article_in_${place}`}>
            <span className='articleDate__service'>{service}</span>
            <span className='articleDate__dot'></span>
            <span className='articleDate__date'>{date}</span>
        </p>
    );
}

export default ArticleDate;
