import { useState, useRef } from 'react';
import AliceCarousel from 'react-alice-carousel';

// components:
import ArrowButtonSlider from '../../../components/ArrowButtonSlider/ArrowButtonSlider';
import ArticleCard from '../../../components/ArticleCard/ArticleCard';
import Button from '../../../components/Button/Button';

import './LastIn.scss';
import 'react-alice-carousel/lib/alice-carousel.css';

const LastIn = ({ title, data, onClick }) => {
  const [firstSlide, setFirstSlide] = useState(1);
  const sliderRef = useRef(null);

  const responsive = {
    0: { items: 1 },
    568: { items: 2 },
    1024: { items: 3 },
  };

  const handleSliderArrows = (e) => {
    console.log(e.currentTarget)
    if (e.currentTarget.dataset.side === 'left') {
      if (firstSlide > 1) {
        const left = firstSlide - 1;
        setFirstSlide(left);
      }
    }
    else if (e.currentTarget.dataset.side === 'right')
      if (firstSlide + numberOfSlidesDisplayed < data.length) {
        const right = firstSlide + 1;
        setFirstSlide(right);
      }

  };

  const handleNextSlide = (e) => {
    sliderRef.current.slideNext();
  }

  const handlePrevSlide = (e) => {
    sliderRef.current.slidePrev();
  }

  const numberOfSlidesDisplayed = 3;
  const renderLeftPlayIconDisabled = firstSlide === 1;
  const renderRightPlayIconDisabled = firstSlide + numberOfSlidesDisplayed === data.length;

  const renderArticleCard = data
    .map((data, index) =>
      (index + 1 >= firstSlide && index + 1 <= firstSlide + numberOfSlidesDisplayed) ?
        <ArticleCard
          key={data.id}
          srcArticle={data?.coverImage?.coverWebp?.url || data?.headerImage?.coverWebp?.url || data?.headerImage?.webp?.url}
          altArticle={'article img'}
          service={data.categories}
          date={data.createdAt}
          title={data.title}
          description={data.bodyShort}
          srcAuthor={data.author.avatar.webp.url}
          altAuthor={'author of articles'}
          name={`${data.author.firstName} ${data.author.lastName}`}
          profession={data.author.occupiedPosition}
          readMoreHref={`/article/${data.slug}`} />
        : null);
  const renderItems = () => (
    data.map(item => (
      <ArticleCard
        key={item.id}
        srcArticle={item?.coverImage?.coverWebp?.url || item?.headerImage?.coverWebp?.url || item?.headerImage?.webp?.url}
        altArticle={'article img'}
        service={item.categories?.join(", ")}
        date={item.createdAt}
        title={item.title}
        description={item.bodyShort}
        srcAuthor={item.author.avatar.webp.url}
        altAuthor={'author of articles'}
        name={`${item.author.firstName} ${item.author.lastName}`}
        profession={item.author.occupiedPosition}
        displayTxt={true}
        readMoreHref={`/article/${item.slug}`} />
    ))
  )

  return (
    <section className="lastIn">
      <div className="lastIn__header">
        <div className="lastIn__wrap-txt">
          <p className="lastIn__p">Last in</p>
          <h1 className="lastIn__h1">{title}</h1>
        </div>
        <Button txt={'Read all post'} onClick={onClick} />
      </div>
      <div className="lastIn__wrap-slider">
        <div className={`lastIn__wrap-slider-card${renderRightPlayIconDisabled ? ' left' : ''}`}>
          {/* {renderArticleCard} */}
          <AliceCarousel
            ref={sliderRef}
            mouseTracking
            items={renderItems()}
            responsive={responsive}
            controlsStrategy='responsive'
            disableDotsControls={true}
            disableButtonsControls={true}
            infinite={true}
          // renderPrevButton={renderPrevBtn}
          // renderNextButton={renderNextBtn}
          />
        </div>
        <div className="lastIn__wrap-play-icons">
          <ArrowButtonSlider
            onClick={handlePrevSlide}
            side={'left'}
            isDisabled={false}
          />
          <ArrowButtonSlider
            onClick={handleNextSlide}
            side={'right'}
            isDisabled={false}
          />
        </div>
      </div>
    </section>
  );
}

export default LastIn;
