// data:
import { homePath, aboutUsPath, caseStudyPath, servicePath, mainBlogPath, contactPath } from './navigatePath';

// svg:
import LinkedIn from './svg/lets_talk_in.svg';
import Behance from './svg/lets_talk_behance.svg';
// import { ReactComponent as IGIcon } from './svg/ig.svg';
// import IGIcon from './svg/ig.svg';
import IGIcon from './img/ig.png';
import Facebook from './svg/lets_talk_facebook.svg';
import Youtube from './svg/twitter.svg';

// img:
import img1 from './img/our_case_study_ar_project_1.png';
import img2 from './img/our_case_study_ar_project_2.png';
import img3 from './img/our_case_study_ar_project_3.png';
import img4 from './img/our_case_study_ar_project_4.png';
import img1sm from './img/our_case_study_ar_project_1_sm.png';
import img2sm from './img/our_case_study_ar_project_2_sm.png';
import img3sm from './img/our_case_study_ar_project_3_sm.png';
import img4sm from './img/our_case_study_ar_project_2_sm.png';
import person from './img/JB_web.png';
import person_2 from './img/JT_web.png';
import person_3 from './img/KK_web.png';
import person_4 from './img/MK_web.png';

export const txtForMenuLinks = [
  { txt: 'home', path: homePath },
  { txt: 'about us', path: aboutUsPath },
  // { txt: 'Services', path: servicePath },
  // { txt: 'Casestudy', path: caseStudyPath },
  { txt: 'news and updates', path: mainBlogPath },
  { txt: 'contact', path: contactPath }];

export const txtForSocialMedia = [
  {
    name: 'LinkedIn',
    icon: LinkedIn,
    href: 'https://www.linkedin.com/company/curiouselement/'
  },
  {
    name: 'Instagram',
    icon: IGIcon,
    href: 'https://www.instagram.com/curious.element/'
  },
  {
    name: 'Facebook',
    icon: Facebook,
    href: 'https://www.facebook.com/CuriousElement'
  },
  {
    name: 'Twitter',
    icon: Youtube,
    href: 'https://twitter.com/CuriousElementX'
  }];

export const txtForHeaderH1 = 'Expand your medical knowledge in augmented reality way';

export const txtForHeaderParagraph = 'Presentation of Sanofi products as a tool for sales representatives, visual merchandisers, product promoters....';

export const txtForOfficeInfo = [
  ['Warszawa, 01-115', 'ul. Danuty Siedzikówny “Inki” 5/39', 'NIP: 527-293- 08-85', 'REGON: 0000849102'],
  ['London', '85 Great Portland Street', 'United Kingdom'],
  ['San Francisco', '75 Broadway, 94111', 'United States',],
];

export const txtForOurService = [
  {
    name: 'Virtual reality',
    boldedStart: 'We’ll',
    underlinedMiddle: 'take your customers',
    endPara: 'on a fully immersive journey, whether it be exploring your products, taking a virtual tour of your facilities, or training your employees.',
    followUp: "Not only for those who are <span class='OurService__blue'>curious.</span> First of all, for those who are <span class='OurService__blue'>ready!",
    buttonLink: '/blog/category/vr'
  },
  {
    name: 'Augmented reality',
    boldedStart: 'Transform',
    underlinedMiddle: 'your business',
    endPara: 'with our advanced Augmented Reality (AR) services. Bring digital information to life and create interactive and immersive experiences.',
    followUp: "Not only for those who are <span class='OurService__blue'>curious.</span> First of all, for those who are <span class='OurService__blue'>ready!",
    buttonLink: '/blog/category/ar'
  },
  {
    name: 'Mixed reality',
    boldedStart: 'Our',
    underlinedMiddle: 'Mixed Reality services',
    endPara: 'allow users to interact with both real and virtual objects in a natural and intuitive way.',
    followUp: "Not only for those who are <span class='OurService__blue'>curious.</span> First of all, for those who are <span class='OurService__blue'>ready!",
    buttonLink: '/blog/category/mr'
  },
  {
    name: 'Game development',
    boldedStart: 'From',
    underlinedMiddle: 'initial requirements gathering',
    endPara: 'to final deployment and maintenance. Our team has the skills and experience to bring your software vision to life.',
    followUp: "Not only for those who are <span class='OurService__blue'>curious.</span> First of all, for those who are <span class='OurService__blue'>ready!",
    buttonLink: '/blog/category/web'
  }

];

export const txtForHowWeWork = [
  {
    title: 'Conceptual Phase',
    description: "At this stage, we begin our journey by engaging with you in extensive workshops or consultations to understand your needs, or perform a code audit review if it's an existing project. Our team will collaborate closely with you to define your vision, explore ideas, and establish a strong foundation for the project."
  },
  {
    title: 'Design/Pre-production Phase',
    description: "Following the conceptualization, we move into the design phase where we use our creative workshops to visualize your concept. Here we create the design document and start developing the user experience (UX) and user interface (UI) designs. This phase ensures that the project's aesthetics and functionality are finely tuned before production."
  },
  {
    title: 'Production Phase',
    description: "After the design phase, we now bring the concept to life. Our team of experienced developers will work tirelessly, leveraging the Unity 3D engine, to create your XR application. This phase involves programming, testing, and iterating until we have a product that meets the quality and performance standards we have set together."
  },
  {
    title: 'Implementation Phase',
    description: "Once we've built your application, it's time to roll it out. In the implementation phase, we integrate the application with your systems and ensure it works seamlessly in your specific environment. We offer assistance with any necessary hardware setups and offer training to ensure a smooth transition and successful deployment."
  },
  {
    title: 'Evaluation Phase',
    description: 'After the application is implemented, we take the time to evaluate its performance. We will collect feedback, monitor user interactions, and measure success based on the agreed-upon key performance indicators (KPIs). This phase is essential to assess whether the application has met its objectives and to identify areas for improvement.'
  },
  {
    title: 'Improvement Phase',
    description: 'Based on the evaluation, we dive into the improvement phase. We believe that every great application can be made even better with regular updates and enhancements. We use the feedback and data collected to make improvements and optimize your XR application, ensuring it continues to exceed expectations and meet the evolving needs of its users.'
  }
];

export const txtForOurClients = ['United States', 'France', 'Switzerland', 'Austria', 'Italy', 'Australia', 'Poland', 'Bulgaria'];

export const txtForOurTechnology = [
  {
    technology: 'Programming languages',
    tools: `Ruby / Elixir / SQL / Javascript / C# / C / C++ / Python`
  },
  {
    technology: 'Augmented Reality Development Platforms',
    tools: `ARCore / AR kit`
  },
  {
    technology: '2D/3D Development Platforms:',
    tools: `Unity / Unreal Engine / Blender / ZBrush`
  },
  {
    technology: 'Databases',
    tools: `MySQL / PostgreSQL`
  },
  {
    technology: 'Frameworks',
    tools: `Ruby On Rails / Phoenis / React / Vue / Boostrap / React Native`
  },
  {
    technology: 'Electronics',
    tools: `Raspberry Pi / STM32 / Arduino / Nvidia CUDA / Open CV / PCB Design SMD/THT soldering`
  },
  {
    technology: 'Others',
    tools: `STL / CircleCl / Gitlab CI / OTP / RSpec Kubernetes  Visual Studio / Tensor Flow / Git`
  },
];

export const txtForMeetOurTeam = [
  {
    img: person,
    name: 'Jakub Burdajewicz',
    profession: `business & sales`,
    email: 'jakub.burdajewicz@curiouselement.com',
    linkedIn: 'https://www.linkedin.com/in/jakubburdajewicz/'
  },
  {
    img: person_2,
    name: 'Jakub Terczyński',
    profession: `technology & programming`,
    email: 'jakub.terczynski@curiouselement.com',
    linkedIn: 'https://www.linkedin.com/in/jakub-terczynski/'
  },
  {
    img: person_3,
    name: 'Krzysztof Kuper',
    profession: `investors & finance`,
    email: 'krzysztof@technologies4fun.com',
    linkedIn: 'https://www.linkedin.com/in/krzysztof-kuper/'
  },
  {
    img: person_4,
    name: 'Maciej Kawiński',
    profession: `project manager`,
    email: 'maciej.kawinski@curiouselement.com',
    linkedIn: 'https://www.linkedin.com/in/maciej-kawi%C5%84ski-9a189459/'
  },
]

export const txtForListArticlesReadMagazine = [
  { title: 'Virtual Reality', description: 'VR service immerses users in a virtual world, providing an interactive and realistic experience.', url: 'category/vr', slug: 'vr' },
  { title: 'Augmented Reality', description: 'Using the museum App at the Technical Museum in Vienna', url: 'category/ar', slug: 'ar' },
  { title: 'Mixed Reality', description: 'Using the museum App at the Technical Museum in Vienna', url: 'category/mr', slug: 'mr' },
  { title: 'Blockchain/NFT', description: 'Using the museum App at the Technical Museum in Vienna', url: 'category/blockchain-nft', slug: 'b-nft' },
  { title: 'Game Development', description: 'Using the museum App at the Technical Museum in Vienna', url: 'category/web', slug: 'web' },
  { title: 'Internet of Things', description: 'Using the museum App at the Technical Museum in Vienna', url: 'category/hardware', slug: 'hw' },
];

export const txtForNavInOurCaseStudyList = ['Virtual Reality', 'Augmented Reality', 'Mixed Reality'];

export const txtForARProjects = [
  {
    img: img1, smallImg: img1sm, imgAlt: 'img alt', service: 'AR', title: 'Escape room', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img2, smallImg: img2sm, imgAlt: 'img alt', service: 'AR', title: 'Xploria', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img3, smallImg: img3sm, imgAlt: 'img alt', service: 'AR', title: 'Escape room2', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img4, smallImg: img2sm, imgAlt: 'img alt', service: 'AR', title: 'Pharmatiq', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
];

export const txtForVRProjects = [
  {
    img: img3, smallImg: img3sm, imgAlt: 'img alt', service: 'VR', title: 'Escape room', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img4, smallImg: img2sm, imgAlt: 'img alt', service: 'VR', title: 'Pharmatiq', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img1, smallImg: img1sm, imgAlt: 'img alt', service: 'VR', title: 'Escape room2', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img2, smallImg: img2sm, imgAlt: 'img alt', service: 'VR', title: 'Xploria', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
];

export const txtForMRProjects = [
  {
    img: img2, smallImg: img2sm, img2, imgAlt: 'img alt', service: 'MR', title: 'Xploria', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img3, smallImg: img3sm, imgAlt: 'img alt', service: 'MR', title: 'Escape room2', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img4, smallImg: img2sm, imgAlt: 'img alt', service: 'MR', title: 'Pharmatiq', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
  {
    img: img1, smallImg: img1sm, imgAlt: 'img alt', service: 'MR', title: 'Escape room', description: `Presentation of Sanofi products as a tool for sales representatives, 
    visual merchandisers, product promoters....`},
];

export const txtForEstimateFirstQuestion = ['VR / AR / MR', 'Game development', 'Mobile app development', 'Product design UX & UI'];

export const txtForEstimateSecondQuestion = ['iOS', 'Android', 'Flutter', 'React Native', 'iOS2', 'Android2', 'Flutter2', 'React Native2'];

// properties for input:
const MIN_LENGTH_FOR_INPUT_NAME = 4;
const MAX_LENGTH_FOR_INPUT_NAME = 100;
const MIN_LENGTH_FOR_INPUT_EMAIL = 4;
const MAX_LENGTH_FOR_INPUT_EMAIL = 60;
const MIN_LENGTH_FOR_INPUT_COUNTRY = 0;
const MAX_LENGTH_FOR_INPUT_COUNTRY = 60;

export const txtForContactForm = [
  {
    txt: 'First name and last name',
    name: 'name',
    type: 'text',
    minLength: MIN_LENGTH_FOR_INPUT_NAME,
    maxLength: MAX_LENGTH_FOR_INPUT_NAME,
    required: true
  },
  {
    txt: 'E-mail',
    name: 'email',
    type: 'email',
    minLength: MIN_LENGTH_FOR_INPUT_EMAIL,
    maxLength: MAX_LENGTH_FOR_INPUT_EMAIL,
    required: true
  },
  {
    txt: 'Country',
    name: 'country',
    type: 'text',
    minLength: MIN_LENGTH_FOR_INPUT_COUNTRY,
    maxLength: MAX_LENGTH_FOR_INPUT_COUNTRY,
    required: true
  },
  {
    txt: 'Budget',
    name: 'budget',
    type: 'number',
    minLength: MIN_LENGTH_FOR_INPUT_COUNTRY,
    maxLength: MAX_LENGTH_FOR_INPUT_COUNTRY,
    required: false
  }
];
