// components:
import ArticlesDate from '../ArticlesDate/ArticleDate';
import ReadMore from '../ReadMore/ReadMore';
import useMedia from '../../hooks/useMedia';

import './ArticleCard.scss';

const ArticleCard = ({
  srcArticle,
  altArticle,
  service,
  date,
  title,
  description,
  srcAuthor,
  altAuthor,
  name,
  profession,
  displayTxt,
  readMoreHref,
  readMoreDark
}) => {

  const { isMobile } = useMedia();

  const isReadMoreDark = typeof readMoreDark !== 'undefined' ? readMoreDark : true;

  return (
    <article className='articleCard__wrap-article'>
      <div className='articleCard__wrap-article-img'>
        <img className='articleCard__article-img' src={srcArticle} alt={altArticle} />
      </div>

      <ArticlesDate service={service} date={date} place={'card'} />

      <h2 className='articleCard__title'>{title}</h2>
      <p className='articleCard__description'>{description}</p>
      <div className='articleCard__wrap-footer'>
        <div className='articleCard__wrap-author'>
          <div className='articleCard__wrap-avatar'>
            <img src={srcAuthor} alt={altAuthor} />
          </div>
          {!isMobile && <div className='articleCard__wrap-txt'>
            <p className='articleCard__name'>{name}</p>
            <p className='articleCard__profession'>{profession}</p>
          </div>}
        </div>

        <ReadMore dark={isReadMoreDark} displayTxt={displayTxt} href={readMoreHref} />

      </div>
    </article>
  );
}

export default ArticleCard;
