import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from '../../axios';

// components:
import MailAndPhone from '../../components/MailAndPhone/MailAndPhone';

//sections:
import EstimateAccordion from './EstimateAccordion/EstimateAccordion';
import ContactForm from './ContactForm/ContactForm';
import useMedia from '../../hooks/useMedia';

import './ContactAndEstimate.scss';

const ContactAndEstimate = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {
  const { isMobile } = useMedia();

  const [estimate, setEstimate] = useState(
    {
      firstAnswer: [],
      secondAnswer: [],
      thirdAnswer: [],
      name: '',
      email: '',
      country: '',
      budget: '0-$100.000',
      nda: 'no',
      information: '',
      privacyPolicy: false
    });

  const [displayContactForm, setDisplayContactForm] = useState(false);

  const navigate = useNavigate();

  useLayoutEffect(() => {
    setDarkLogoIcon(true);
    setDarkMenuIcon(true);
    setDisplayFoter(false);
  }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter]);

  const handleClickRectangle = (e) => {
    e.currentTarget.classList.toggle('active');
    const newEstimate = { ...estimate };
    const found = newEstimate[e.currentTarget.dataset.keyname].some(item => item === e.currentTarget.textContent);
    (!found) ?
      newEstimate[e.currentTarget.dataset.keyname].push(e.currentTarget.textContent)
      : newEstimate[e.currentTarget.dataset.keyname] = newEstimate[e.currentTarget.dataset.keyname].filter(item => item !== e.currentTarget.textContent);
    setEstimate(newEstimate);
  };

  const handleInputContact = (e) => {
    const newEstimate = { ...estimate };
    if (e.currentTarget.type === 'radio') newEstimate.nda = e.currentTarget.value;
    else if (e.currentTarget.type === 'checkbox') newEstimate.privacyPolicy = !newEstimate.privacyPolicy;
    else newEstimate[e.currentTarget.name] = e.target.value;
    setEstimate(newEstimate);
  }

  const handleSubmitForm = (e) => {
    e.preventDefault();
    const dataToBeSent = {
      interested_in: estimate.firstAnswer.join(", "),
      project_scope: estimate.secondAnswer.join(", "),
      found_out_from: estimate.thirdAnswer.join(", "),
      name: estimate.name,
      email: estimate.email,
      country: estimate.country,
      budget: estimate.budget,
      nda: estimate.nda,
      information: estimate.information,
      privacy_policy: estimate.privacyPolicy
    };

    axios.post('contacts/curious', { contact: dataToBeSent }).then(() => {
      alert("Message sent. Thank you.");
      setEstimate({
        firstAnswer: [],
        secondAnswer: [],
        thirdAnswer: [],
        name: '',
        email: '',
        country: '',
        budget: '0-$100.000',
        nda: 'no',
        information: '',
        privacyPolicy: false
      })
    }).catch((e) => {
      alert('Unfortunately, your message could not be sent, please try again later.')
    })
  }

  const renderRightSidePage = displayContactForm ?
    <ContactForm
      handleInputContact={handleInputContact}
      handleSubmitForm={handleSubmitForm}
      estimate={estimate} />
    :
    <EstimateAccordion
      handleClickRectangle={handleClickRectangle}
      setDisplayContactForm={setDisplayContactForm}
      estimate={estimate} />

  const renderRightTitle = displayContactForm ? 'Provide your details so that we can contact you' :
    'Answer a question to get more precise estimation'


  return (
    <div className='contactAndEstimate'>
      {!isMobile && <div className='contactAndEstimate__left'>
        <h1 className='contactAndEstimate__h1'>Hi there!</h1>
        <p className='contactAndEstimate__p'>Happy to see that you decided to contact us</p>
        <MailAndPhone />
      </div>}
      <div className='contactAndEstimate__right'>
        <h3 className='contactAndEstimate__h3'>{renderRightTitle}</h3>
        {renderRightSidePage}
      </div>
    </div>
  );
}

export default ContactAndEstimate;
