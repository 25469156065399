export const carouselItems = [
  {
    h2: {
      category: 'Virtual Reality',
      text: 'Immersive Experience'
    },
    h1: [
      'Unleash the power of Virtual Reality and',
      'take Your Business to the next level'
    ],
    para: 'We\'ll take your customers on a fully immersive journey, whether it be exploring your products, taking a virtual tour of your facilities, or training your employees.',
    link: {
      href: '/blog/category/vr',
      text: 'Explore case studies'
    }
  },
  {
    h2: {
      category: 'Augmented Reality',
      text: 'From Digital To Life'
    },
    h1: [
      'Augment Your Business with',
      'cutting-edge AR solutions'
    ],
    para: 'Transform your business with our advanced Augmented Reality (AR) services. Bring digital information to life and create interactive and immersive experiences.',
    link: {
      href: '/blog/category/ar',
      text: 'Explore case studies'
    }
  },
  {
    h2: {
      category: 'Mixed Reality',
      text: 'Interactive Detailings'
    },
    h1: [
      'Experience the future of interaction',
      'with our Mixed Reality services'
    ],
    para: 'Our Mixed Reality services allow users to interact with both real and virtual objects in a natural and intuitive way.',
    link: {
      href: '/blog/category/mr',
      text: 'Explore case studies'
    }
  },
  {
    h2: {
      category: 'Game Development',
      text: 'Comprehensive Approach'
    },
    h1: [
      'Leading the way in custom',
      'Game Development for Businesses'
    ],
    para: 'From initial requirements gathering to final deployment and maintenance. Our team has the skills and experience to bring your software vision to life.',
    link: {
      href: '/blog/category/web',
      text: 'Explore case studies'
    }
  },
]