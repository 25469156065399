// svg:
import arrowIcon from '../../../svg/read_magazine_card_arrow.svg';

import './ReadMagazineCard.scss';

const ReadMagazineCard = ({ title, description, onClick }) => {
    return (
        <div className="readMagazineCard">
            <h1 className="readMagazineCard__h1">{title}</h1>
            <p className="readMagazineCard__p">{description}</p>
            <div className="readMagazineCard__wrap-read-magazine">
                <p className='readMagazineCard__txt' onClick={onClick}>Read more</p>
                <img className='readMagazineCard__arrow-icon' src={arrowIcon} alt='arrow icon'onClick={onClick}/>
            </div>
        </div>
    );
}

export default ReadMagazineCard;
