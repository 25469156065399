// components:
import ProjectCard from '../ProjectCard/ProjectCard';

// data:
import { txtForARProjects } from '../../../constants';

import './ARProjects.scss';

const ARProjects = () => {
    const renderProjectCards = txtForARProjects
        .map(data => <ProjectCard
            key={data.title}
            img={data.img}
            smallImg ={data.smallImg}
            imgAlt={data.imgAlt}
            service={data.service}
            title={data.title}
            description={data.description} />)

    return (
        <>
            {renderProjectCards}
        </>
    );
}

export default ARProjects;
