// components:
import ProjectCard from '../ProjectCard/ProjectCard';

// data:
import { txtForVRProjects } from '../../../constants';

import './VRProjects.scss';

const VRProjects = () => {
    const renderProjectCards = txtForVRProjects
        .map(data => <ProjectCard
            key={data.title}
            img={data.img}
            smallImg={data.smallImg}
            imgAlt={data.imgAlt}
            service={data.service}
            title={data.title}
            description={data.description} />)
    return (
        <>
            {renderProjectCards}
        </>
    );
}

export default VRProjects;
