import './TextArea.scss';

const TextArea = ({ value, onChange, name, txt }) => {

    return (
        <div className='textArea__wrap'>
            <label className='textArea__label' htmlFor={name}>{txt}</label>
            <textarea
                className='textArea__input'
                type='text'
                id={name}
                name={name}
                placeholder={txt}
                value={value}
                onChange={onChange} />
        </div>
    );
}

export default TextArea;
