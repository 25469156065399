import axios from "axios";

const instance = axios.create({
  baseURL: `https://api.curiouselement.com/api/v1/`,
  // baseURL: `http://localhost:3000/api/v1/`,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  timeout: 15000
});

export default instance;