import { useState } from "react";
import { Routes, Route } from "react-router-dom";

// components: 
import Cursor from "./components/Cursor/Cursor";
import Menu from './components/Menu/Menu';
import Footer from './components/Footer/Footer';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

// pages: 
import LandingPage from './Pages/LandingPage/LandingPage';
import AboutUs from './Pages/AboutUs/AboutUs';
import ListArticles from './Pages/ListArticles/ListArticles';
import OurCaseStudyList from './Pages/OurCaseStudyList/OurCaseStudyList';
import ContactAndEstimate from './Pages/ContactAndEstimate/ContactAndEstimate';
import BlogArticle from "./Pages/BlogArticle/BlogArticle";

import './App.scss';

const App = () => {
  const [darkLogoIcon, setDarkLogoIcon] = useState(true);
  const [darkMenuIcon, setDarkMenuIcon] = useState(false);
  const [displayFooter, setDisplayFoter] = useState(true);
  const isTouchDevice = () => {
    return ('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0);
  };

  const renderFooter = displayFooter ?
    <div className='App__bgc_gray'>
      <Footer />
    </div> : null;

  return (
    <>
      {!isTouchDevice() && <Cursor displayFooter={displayFooter} />}
    <div className="App">
      <Menu
        darkLogoIcon={darkLogoIcon}
        darkMenuIcon={darkMenuIcon}
      />
      <ScrollToTop />
      <Routes>
        <Route
          path="/"
          element={<LandingPage
            setDarkLogoIcon={setDarkLogoIcon}
            setDarkMenuIcon={setDarkMenuIcon}
            setDisplayFoter={setDisplayFoter} />} />
        <Route
          path="about-us"
          element={<AboutUs
            setDarkLogoIcon={setDarkLogoIcon}
            setDarkMenuIcon={setDarkMenuIcon}
            setDisplayFoter={setDisplayFoter} />} />
        <Route path="blog/*"
          element={<ListArticles
            setDarkLogoIcon={setDarkLogoIcon}
            setDarkMenuIcon={setDarkMenuIcon}
            setDisplayFoter={setDisplayFoter} />}>
        </Route>
        <Route
          path="article/:slug"
          element={
            <BlogArticle
              setDarkLogoIcon={setDarkLogoIcon}
              setDarkMenuIcon={setDarkMenuIcon}
              setDisplayFoter={setDisplayFoter} />
          }>
        </Route>
        <Route
          path="case-study"
          element={<OurCaseStudyList
            setDarkLogoIcon={setDarkLogoIcon}
            setDarkMenuIcon={setDarkMenuIcon}
            setDisplayFoter={setDisplayFoter} />} />
        <Route
          path="contact"
          element={<ContactAndEstimate
            setDarkLogoIcon={setDarkLogoIcon}
            setDarkMenuIcon={setDarkMenuIcon}
            setDisplayFoter={setDisplayFoter} />} />
      </Routes>
      {renderFooter}
    </div>
    </>
  );
}

export default App;
