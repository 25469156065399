// components:
import Button from '../../../components/Button/Button';
import Checkbox from '../../../components/Checkbox/Checkbox';
import Input from '../../../components/Input/Input';
import Radio from '../../../components/Radio/Radio';
import Select from '../../../components/Select/Select';
import TextArea from '../../../components/TextArea/TextArea';

// data:
import { txtForContactForm } from '../../../constants';

// svg:
import arrow from '../../../svg/lets_talk_arrow.svg';


import './ContactForm.scss';

const ContactForm = ({ estimate, handleInputContact, handleSubmitForm }) => {

  const renderInputs = () => {
    return (txtForContactForm
      .map(input =>
        <Input
          key={input.name}
          data={input}
          value={estimate}
          onChange={handleInputContact}
        />
      )
    )
  };

  return (
    <div className='contactForm'>
      <form action="submit" className='contactForm__form' onSubmit={handleSubmitForm}>
        {renderInputs()}
        {/* <Select
          onChange={handleInputContact}
          value={estimate.budget}
          options={["0-$100.000", "$200.000-$500.000", "$500.000-$1.000.000"]}
          name={'budget'}
          label={'Budget'} /> */}
        <Radio
          txt={'Do you need an NDA?'}
          value={['yes', 'no']}
          checked={estimate.nda}
          onChange={handleInputContact}
        />
        <TextArea
          txt={'Additional information'}
          onChange={handleInputContact}
          value={estimate.information}
          name={'information'}
        />
        <Checkbox
          data={estimate.privacyPolicy}
          onChange={handleInputContact}
          name={'privacyPolicy'}
          txt={'I accept Privacy Policy and agree to receive emails from Droids On Roids'}
          spanTxt={'*'}
          required={true} />
        <Button
          txt={'Submit'}
          svg={arrow} />
      </form>
    </div>
  );
}

export default ContactForm;
