import { forwardRef } from 'react';

import './HeaderBlack.scss';

const HeaderBlack = forwardRef((props, ref) => {
    const {title, span, txt, nav, navTxt, handleNavClick } = props;
    const renderNavButton = nav ? navTxt.map(txt =>
        <li key={txt} className='headerBlack__li'>
            <button 
            data-name={txt}
            onClick={handleNavClick} 
            className='headerBlack__btn'>{txt}</button></li>)
        : null;

    const renderNav = nav ?
        <nav className='headerBlack__nav'>
            <ul className='headerBlack__ul'>{renderNavButton}</ul></nav>
        : null;

    return (
        <header className="headerBlack" ref={ref}>
            <div className="headerBlack__wrap-title">
                <h1 className="headerBlack__h1">{title}</h1>
                <h2 className="headerBlack__h2">
                    <span className="headerBlack__blue">{span}</span>{txt}
                </h2></div>
            {renderNav}
        </header>
    );
});

export default HeaderBlack;
