import { useLayoutEffect, useRef, useState, useEffect } from 'react';

// sections:
import Header from '../../components/Header/Header';
import OurService from './Section/OurService/OurService';
import MeetOurTeam from './Section/MeetOurTeam/MeetOurTeam';
import OurCasestudy from './Section/OurCasestudy/OurCasestudy';
import OurArticles from './Section/OurArticles/OurArticles';
import OthersSay from './Section/OthersSay/OthersSay';
import useMedia from '../../hooks/useMedia';

import './LandingPage.scss';

const LandingPage = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {
  const { isMobile } = useMedia();
  useLayoutEffect(() => {
    setDarkLogoIcon(true);
    setDarkMenuIcon(isMobile);
    setDisplayFoter(true);
  }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter, isMobile]);

  const [heightForHeader, setHeightForHeader] = useState(0);
  const [heightForMeetOurTeam, setHeightForMeetOurTeam] = useState({ height1: 0, height2: 0, height3: 0 });
  const [heightForOurArticles, setHeightForOurArticles] = useState(0);
  const [heightForOthersSay, setHeightForOthersSay] = useState(0);

  const refHeader = useRef(null);
  const refOurService = useRef(null);
  const refMeetOurTeam = useRef(null);
  const refOurCasestudy = useRef(null);
  const refOurArticles = useRef(null);
  const refOthersSay = useRef(null);

  useEffect(() => {

    // sectionHeight:
    const section1 = refHeader.current.clientHeight;
    const section2 = refOurService.current.clientHeight;
    const section3 = refMeetOurTeam.current.clientHeight;
    const section4 = refOurCasestudy.current?.clientHeight || 0;
    const section5 = refOurArticles.current.clientHeight;
    const section6 = refOthersSay.current?.clientHeight || 0;

    setHeightForHeader(section1);

    setHeightForMeetOurTeam(
      {
        height1: section1 + section2,
        height2: section1 + section2 + section3 + section4,
        height3: section1 + section2 + section3
      });
    setHeightForOurArticles(section1 + section2 + section3 + section4);
    setHeightForOthersSay(
      {
        height1: section1 + section2 + section3 + section4 + section5 + section6,
        height2: section1 + section2 + section3 + section4 + section5,
      });
  }, []);

  return (
    <div className="landingPage">
      <Header height={heightForHeader} ref={refHeader} />
      <OurService ref={refOurService} />
      <div className='landingPage__bgc_dark'>
        <MeetOurTeam height={heightForMeetOurTeam} ref={refMeetOurTeam} />
        {/* <OurCasestudy ref={refOurCasestudy} /> */}
      </div>
      <OurArticles height={heightForOurArticles} ref={refOurArticles} />
      {/* <OthersSay height={heightForOthersSay} ref={refOthersSay} /> */}
    </div>
  );
}

export default LandingPage;
