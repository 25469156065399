import { createContext, useState, useEffect } from "react";
import axios from './axios';

// import { backendUrl } from "./environmentConstants";

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [categoriesData, setCategoriesData] = useState([]);
  const [dataVR, setDataVR] = useState([]);
  const [dataAR, setDataAR] = useState([]);
  const [dataMR, setDataMR] = useState([]);
  const [dataWebsite, setDataWebsite] = useState([]);
  const [dataBlockchainNFT, setDataBlockchainNFT] = useState([]);
  const [dataHardware, setDataHardware] = useState([]);
  const [latestsArticles, setLatestArticles] = useState([]);


  const fetchDataBlog = async (category) => {
    const fetchAndSet = async (slug, callback) => {
      const response = await (await axios.get('articles', { params: { byCategory: slug, limit: 6 } })).data.data;
      callback(response);
    }
    switch (category) {
      case 'vr':
        fetchAndSet('vr', setDataVR);
        break;
      case 'ar':
        fetchAndSet('ar', setDataAR);
        break;
      case 'mr':
        fetchAndSet('mr', setDataMR);
        break;
      case 'web':
        fetchAndSet('web', setDataWebsite);
        break;
      case 'b-nft':
        fetchAndSet('b-nft', setDataBlockchainNFT);
        break;
      case 'hw':
        fetchAndSet('hw', setDataHardware);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    fetchDataBlog('vr');
    fetchDataBlog('ar');
    fetchDataBlog('mr');
    fetchDataBlog('web');
    fetchDataBlog('b-nft');
    fetchDataBlog('hw');
  }, []);

  useEffect(() => {
    const fetchCategories = async (categories) => {
      const response = await (await axios.get('categories', { params: { bySlug: categories } })).data.data;
      setCategoriesData(response);
    }

    fetchCategories(['vr', 'ar', 'mr', 'web', 'b-nft', 'hw']);
  }, []);

  useEffect(() => {
    const fetchLatest = async () => {
      const response = await (await axios.get('articles', { params: { order: 'by_recent', limit: 2 } })).data.data;
      setLatestArticles(response);
    }
    fetchLatest();
  }, [])

  // scroll animation params - LandingPage-MeetOurTeam:
  const [loadAnimationMeetOurTeam, setLoadAnimationMeetOurTeam] = useState({
    line1: false,
    line2: false,
    line3: false,
    personRow1: false,
    personRow2: false,
  });

  return (
    <AppContext.Provider
      value={{
        dataAR,
        dataVR,
        dataMR,
        dataWebsite,
        dataBlockchainNFT,
        dataHardware,
        categoriesData,
        latestsArticles,
        loadAnimationMeetOurTeam,
        setLoadAnimationMeetOurTeam
      }}>
      {children}
    </AppContext.Provider>
  )
};

export default AppProvider;