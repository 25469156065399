import { forwardRef } from "react";

// component:
import Globe from "../Globe/Globe";

// data:
import { txtForOurClients } from '../../../constants';

import './OurClients.scss';

const OurClients = forwardRef((props, ref) => {

    const renderCountries = txtForOurClients
        .map(country => <p key={country} className="ourClients__country">{country}</p>)

    return (
        <section className="ourClients" ref={ref}>
            <div className="ourClients__wrap txt">
                <h1 className="ourClients__h1">Our Clients</h1>
                <p className="ourClients__p">Presently our clients are located all around the world in 4 time zones.</p>
                <div className="ourClients__wrap-countries">
                    {renderCountries}
                </div>
            </div>
            <div className="ourClients__wrap globe">
                <Globe />
            </div>
        </section>
    );
});

export default OurClients;
