// components:
import ProjectCard from '../ProjectCard/ProjectCard';

// data:
import { txtForMRProjects } from '../../../constants';


import './MRProjects.scss';

const MRProjects = () => {
    const renderProjectCards = txtForMRProjects
        .map(data => <ProjectCard
            key={data.title}
            img={data.img}
            smallImg ={data.smallImg}
            imgAlt={data.imgAlt}
            service={data.service}
            title={data.title}
            description={data.description} />)

    return (
        <>
            {renderProjectCards}
        </>
    );
}

export default MRProjects;
