import { useNavigate } from "react-router-dom";

// components:
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import Button from '../../components/Button/Button';
import HeaderBlog from '../../components/HeaderBlog/HeaderBlog';
import Line from '../../components/Line/Line';

// data:
import { mainBlogPath } from '../../navigatePath';

// images:
import bgImageForHeader from '../../img/blog_category_header_1.png';

import './BlogCategory.scss';

const BlogCategory = ({ title, data, className }) => {

  const firstArticle = data[0];
  const restArticles = data.slice(1);

  const navigate = useNavigate();

  const renderArticleCard = () => restArticles
    .map(data =>
      <ArticleCard
        key={data.id}
        srcArticle={data?.coverImage?.coverWebp?.url || data?.headerImage?.coverWebp?.url || data?.headerImage?.webp?.url}
        altArticle={'article img'}
        service={data.categories}
        date={data.createdAt}
        title={data.title}
        description={data.bodyShort}
        srcAuthor={data.author.avatar.webp.url}
        altAuthor={'author of articles'}
        name={`${data.author.firstName} ${data.author.lastName}`}
        profession={data.author.occupiedPosition}
        displayTxt={'Read more'}
        readMoreHref={`/article/${data.slug}`}
        readMoreDark={true} />);

  const handleBackToMainBlog = () => navigate(mainBlogPath);

  return (
    <div className='blogCategory'>
      <HeaderBlog
        service={firstArticle?.categories}
        date={firstArticle?.createdAt}
        txtForH1={firstArticle?.title}
        txtForDescription={firstArticle?.bodyShort}
        image={firstArticle?.headerImage?.webp?.url || bgImageForHeader}
        readMoreHref={`/article/${firstArticle?.slug}`}
      />
      <main className='blogCategory__main'>
        <div className='blogCategory__wrap'>
          <div className='blogCategory__title'>
            <p className='blogCategory__p'>Everything in</p>
            <h1 className={`blogCategory__h1 ${className}`}>{title}</h1>
          </div>
          <Button
            txt={'Back to all categories'}
            onClick={handleBackToMainBlog} />
        </div>
        <section className='blogCategory__articles'>
          {renderArticleCard()}
        </section>
        <Line className={'blogCategory'} />
        <Line className={'blogCategory'} />
      </main>
    </div>
  );
}

export default BlogCategory;
