import { forwardRef } from 'react';
// data:
import { txtForHowWeWork } from '../../../constants';

import './HowWeWork.scss';

const HowWeWork = forwardRef((props, ref) => {

  const renderTxtHowWeWork = txtForHowWeWork
    .map((item, index) =>
      <div className="howWeWork__rectangle" key={item.title}>
        <div className="howWeWork__number">{`0${++index}`}</div>
        <div className="howWeWork__txt">
          <h2 className="howWeWork__h2">{item.title}</h2>
          <p className="howWeWork__p">
            {item.description}
            {item.span ? <span>{item.span}</span> : null}
          </p>
        </div>
      </div>)

  return (
    <section className="howWeWork" ref={ref}>
      <div className="howWeWork__wrap">
        <h1 className="howWeWork__h1">How we work?</h1>
        <p className="howWeWork__p">At Curious Element, we take a client-centric approach to our projects. We begin by gathering information about the client's goals, target audience and technical requirements, and continue to involve the client in each step of the process, from concept development to testing and deployment.
        </p>
        <p className="howWeWork__p">We emphasize on clear communication, collaboration and making sure that we meet and exceed client's expectations in terms of quality, timelines and budget.</p>
      </div>
      <div className="howWeWork__wrap">
        {renderTxtHowWeWork}
      </div>
    </section>
  );
});

export default HowWeWork;
