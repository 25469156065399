import { forwardRef } from 'react';

// img:
import img1 from '../../../img/AU_who_we_are_1.png';
import img2 from '../../../img/AU_who_we_are_2.png';
import img3 from '../../../img/AU_who_we_are_3.png';
import img4 from '../../../img/AU_who_we_are_4.png';

import './WhoWeAre.scss';

const WhoWeAre = forwardRef((props, ref) => {

    return (
        <section className="whoWeAre" ref={ref}>
            <h2 className="whoWeAre__h2">Who we are?</h2>
            <div className="whoWeAre__wrap">
                <div className="whoWeAre__wrap-img">
                    <img src={img1} alt="" />
                </div>
                <div className="whoWeAre__wrap-img">
                    <img src={img2} alt="" />
                </div>
            </div>
            <div className="whoWeAre__wrap">
                <div className="whoWeAre__wrap-txt">
                    <h1 className="whoWeAre__h1">Curious Element</h1>
                    <p className="whoWeAre__description">Presenting Curious Element Poland sp. z o.o., a dynamic company established in 2016 by two enterprising individuals, Jakub Burdajewicz and Jakub Terczyński. Since its inception, our firm has been laser-focused on providing top-notch products in the realm of virtual reality, augmented reality, and mixed reality. Drawing upon the founders' extensive hardware and software expertise, we have successfully conceptualized, developed, and implemented innovative solutions for diverse sectors including events, pharmaceuticals, exhibitions, interior design, and fintech.</p><p className="whoWeAre__description">
                    In 2022, we proudly welcomed Maciej Kawiński, a seasoned producer and game designer, to our accomplished team. This collaboration led to the birth of Curious Games Prosta Spółka Akcyjna in 2023, aimed at producing the highly anticipated VR game "Slap Fighter VR". In May 2023, we reached a significant milestone by attaining the esteemed status of an official supplier for Meta Platforms, Inc.</p><p className="whoWeAre__description">
                    At Curious Element Poland, we are driven by a passion for pushing the boundaries of technology and creating immersive experiences. With our unrivaled expertise in virtual and augmented reality, we are committed to revolutionizing industries and providing our clients with exceptional solutions that exceed their expectations.</p>
                </div>
                <div className="whoWeAre__wrap-img">
                    <img src={img3} alt="" />
                </div>
            </div>
            <div className="whoWeAre__wrap">
                <div className="whoWeAre__wrap-img">
                    <img src={img4} alt="" />
                </div>
                <div className="whoWeAre__wrap-p">
                    <p className="whoWeAre__p"><span className="whoWeAre__bold">We create</span> <span className="whoWeAre__blue">strategies, set roadmaps, design solutions</span>, create and implement products. From concept to implementation, from strategic to tactical.</p>
                </div>
            </div>
            <div className='whoWeAre__line'>
                <div className='whoWeAre__active-line'></div>
            </div>
            <div className='whoWeAre__line'>
                <div className='whoWeAre__active-line'></div>
            </div>
        </section>
    );
});

export default WhoWeAre;
