import './Input.scss';

const Input = ({ data, onChange, value }) => {

  return (
    <div key={data.name} className={`input__wrap ${data.type}`}>
      <label className='input__label' htmlFor={data.name}>{data.txt}</label>
      {data.name === 'budget' ? <div className='budget_input_wrapper'>
        <input
          className='input__input'
          type={data.type}
          id={data.name}
          name={data.name}
          placeholder={data.txt}
          minLength={data.minLength}
          maxLength={data.maxLength}
          value={value[data.name]}
          onChange={onChange}
          required={data.required} />
      </div>
        :
        <input
          className='input__input'
          type={data.type}
          id={data.name}
          name={data.name}
          placeholder={data.txt}
          minLength={data.minLength}
          maxLength={data.maxLength}
          value={value[data.name]}
          onChange={onChange}
          required={data.required} />
      }
    </div>
  );
}

export default Input;
