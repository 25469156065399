import { forwardRef, useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";

import useMedia from '../../../../hooks/useMedia';

// components:
import ArticleCard from '../../../../components/ArticleCard/ArticleCard';
import Button from '../../../../components/Button/Button';
import Line from '../../../../components/Line/Line';

// data:
import { mainBlogPath } from '../../../../navigatePath';

// img:
import author1 from '../../../../img/our_articles_author_1.png';
import article1 from '../../../../img/our_articles_article_1.png';

import { AppContext } from '../../../../AppContext';

import './OurArticles.scss';

const OurArticles = forwardRef(({ height }, ref) => {
  const { isMobile } = useMedia();
  const [line1, setLine1] = useState(false);
  const { latestsArticles } = useContext(AppContext);

  const navigate = useNavigate();
  const handleNavigateTo = () => navigate(mainBlogPath);

  useEffect(() => {
    const lineScrollHandler = () => {
      if (!isMobile) setLine1(window.scrollY > height - 200);
    }
    window.addEventListener("scroll", lineScrollHandler);
    return () => window.removeEventListener("scroll", lineScrollHandler);
  }, [height, isMobile]);

  const renderArticles = () => latestsArticles.map((item, index) =>
    <ArticleCard
      key={index}
      srcArticle={item?.coverImage?.coverWebp?.url || item?.headerImage?.coverWebp?.url || item?.headerImage?.webp?.url || article1}
      altArticle={item.title}
      service={item.categories.join(", ")}
      date={item.createdAt}
      title={item.title}
      description={item.bodyShort}
      srcAuthor={item.author?.avatar?.webp?.url || author1}
      altAuthor={`${item.author?.firstName} ${item.author?.lastName}`}
      name={`${item.author?.firstName} ${item.author?.lastName}`}
      profession={item.author?.occupiedPosition}
      displayTxt={true}
      readMoreHref={`/article/${item.slug}`}
    />)

  return (
    <section className='ourArticles' ref={ref}>
      <div className='ourArticles__wrap-h1'>
        <h1 className='ourArticles__h1'>news and updates</h1>
        <Button txt={'Read more articles'} onClick={handleNavigateTo} />
      </div>
      <main className='ourArticles__articles'>
        {renderArticles()}
      </main>
      {!isMobile && <Line className='ourArticles' active={line1} />}
    </section>
  );
})

export default OurArticles;
