import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from '../../axios';
import useMedia from '../../hooks/useMedia';
import {
  LinkedinShareButton,
  FacebookShareButton,
  TwitterShareButton
} from 'react-share';

import { ReactComponent as FBIcon } from '../../svg/fb.svg';
import { ReactComponent as LinkedInIcon } from '../../svg/linked-in.svg';
import { ReactComponent as TTIcon } from '../../svg/twitter.svg';

// section:
import HeaderBlack from '../../components/HeaderBlack/HeaderBlack';
import ArticleCard from '../../components/ArticleCard/ArticleCard';

import './BlogArticle.scss';



const BlogArticle = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {
  const refHeaderBlack = useRef(null);
  const { slug } = useParams();
  const { isMobile } = useMedia();

  const [articleData, setArticleData] = useState({});
  const [relatedArticles, setRelatedArticles] = useState([]);

  useLayoutEffect(() => {
    setDarkLogoIcon(false);
    setDarkMenuIcon(false);
    setDisplayFoter(true);

    const fetchArticle = async () => {
      const response = await (await axios.get(`articles/${slug}`)).data.data;
      setArticleData(response);
    }

    const fetchRelated = async () => {
      const response = await (await axios.get('articles', { params: { limit: 4 } })).data.data;
      setRelatedArticles(response);
    }

    fetchArticle();
    fetchRelated();
  }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter, slug]);

  const renderTags = () => (
    articleData?.tags?.map((tag, index) => (
      <div key={index} className='tag'>
        {tag}
      </div>
    ))
  )

  const shareURL = window.location.href;
  const shareText = "";

  return (
    <div className="blogArticle">
      <header className="headerBlack" ref={refHeaderBlack}>
        <div className="headerBlack__wrap-title">
          <h1 className="headerBlack__h1">{articleData?.title}</h1>
          <div className='category_and_date'>
            <div>
              <span className='articleDate__service'>{articleData?.categories?.join(", ")}</span>
              <span className='articleDate__dot'></span>
              <span className='articleDate__date'>{articleData?.createdAt}</span>
            </div>
            <div>
              <div className='articleCard__wrap-author'>
                <div className='articleCard__wrap-avatar'>
                  <img src={articleData?.author?.avatar?.webp?.url} alt={`${articleData?.author?.firstName} ${articleData?.author?.lastName}`} />
                </div>
                <div className='articleCard__wrap-txt'>
                  <p className='articleCard__name'>{`${articleData?.author?.firstName} ${articleData?.author?.lastName}`}</p>
                  <p className='articleCard__profession'>{articleData?.author?.occupiedPosition}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className='headerImage__wrap'>
        <img src={articleData?.headerImage?.headerWebp?.url} alt={articleData?.title} />
      </div>


      <div className='content__wraper'>
        {!isMobile && <div className='share__wrap'>
          <h4>Share this</h4>
          <div>
            <LinkedinShareButton url={shareURL}>
              <LinkedInIcon />LinkedIn
            </LinkedinShareButton>
            <FacebookShareButton
              url={shareURL}
              quote={shareText}
            >
              <FBIcon />Facebook
            </FacebookShareButton>
            <TwitterShareButton
              url={shareURL}
              title={shareText}>
              <TTIcon />Twitter
            </TwitterShareButton>
          </div>
        </div>}
        <div className='content' dangerouslySetInnerHTML={{ __html: articleData?.body }}></div>

        {isMobile && <div className='share__wrap' style={{ paddingTop: '5rem', paddingBottom: '5rem' }}>
          <h4>Share this</h4>
          <div>
            <LinkedinShareButton url={shareURL}>
              <LinkedInIcon />LinkedIn
            </LinkedinShareButton>
            <FacebookShareButton
              url={shareURL}
              quote={shareText}
            >
              <FBIcon />Facebook
            </FacebookShareButton>
            <TwitterShareButton
              url={shareURL}
              title={shareText}>
              <TTIcon />Twitter
            </TwitterShareButton>
          </div>
        </div>}
      </div>
      <div style={{ border: '1px solid #F5F7F8', margin: '0px 9.25%', padding: '0px' }}></div>

      {articleData?.tags?.length !== 0 && <div className='tags_wrap'>
        <h4>Tag article</h4>
        {renderTags()}

      </div>}
      <div style={{ border: '1px solid #F5F7F8', margin: '0px 9.25%', padding: '0px' }}></div>

      <div className='youMightAlsoLike__wrap'>
        <h4>you might <br /> also like</h4>
        <div className='articles__wrap'>
          {relatedArticles.map((item, index) => {
            if (isMobile && index > 1) return null;
            return (
              <ArticleCard
                key={index}
                srcArticle={item?.coverImage?.coverWebp?.url || item?.headerImage?.coverWebp?.url || item?.headerImage?.webp?.url}
                altArticle={'article img'}
                service={item.categories?.join(", ")}
                date={item.createdAt}
                title={item.title}
                description={item.bodyShort}
                srcAuthor={item.author.avatar.webp.url}
                altAuthor={'author of articles'}
                name={`${item.author.firstName} ${item.author.lastName}`}
                profession={item.author.occupiedPosition}
                displayTxt={'Read more'}
                readMoreHref={`/article/${item.slug}`}
                readMoreDark={true}
              />
            )
          })}
        </div>
      </div>

    </div>
  );
}

export default BlogArticle;
