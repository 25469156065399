import { useEffect, useLayoutEffect, useState, useContext } from 'react';
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { AppContext } from '../../AppContext';

// section:
import HeaderBlog from '../../components/HeaderBlog/HeaderBlog';
import LastIn from './LastIn/LastIn';

// components:
import ReadMagazineCard from './ReadMagazineCard/ReadMagazineCard';

// pages:
import BlogCategory from "../BlogCategory/BlogCategory";

// data:
import { txtForListArticlesReadMagazine } from '../../constants';
import { mainBlogPath } from '../../navigatePath';

// img:
import bgImageForHeader from '../../img/list_articles_header.png';

import './ListArticles.scss';

const ListArticles = ({ setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter }) => {

  const {
    dataVR,
    dataAR,
    dataMR,
    dataWebsite,
    dataBlockchainNFT,
    dataHardware,
    categoriesData
  } = useContext(AppContext);
  const location = useLocation();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    setDarkLogoIcon(true);
    setDarkMenuIcon(true);
    setDisplayFoter(true);
  }, [setDarkLogoIcon, setDarkMenuIcon, setDisplayFoter]);

  const handleNavigateTo = (url) => navigate(url);

  // if (dataAR === undefined || dataVR === undefined || dataMR === undefined) return;

  const renderReadMagazineCard = txtForListArticlesReadMagazine
    .map(card => {
      const categoryFromBacked = categoriesData.find(obj => obj.slug === card.slug);
      return (
        <ReadMagazineCard
          key={card.slug}
          title={categoryFromBacked?.name || card.title}
          description={categoryFromBacked?.description}
          onClick={() => handleNavigateTo(card.url)}
        />
      )
    });

  const renderBlogListArticles =
    location.pathname === mainBlogPath && dataVR[0] !== undefined ? <>
      <HeaderBlog
        service={dataVR[0].tags}
        date={dataVR[0].createdAt}
        txtForH1={dataVR[0].title}
        txtForDescription={dataVR[0].bodyShort}
        image={dataVR[0].headerImage?.webp?.url || bgImageForHeader}
        readMoreHref={`/article/${dataVR[0].slug}`}
      />
      <section className="listArticles__black_space">Fuel for your innovation fire</section>
      <section className="listArticles__wrap_magazine_card">
        {renderReadMagazineCard}
      </section>
      <LastIn title={'Virtual Reality'} data={dataVR} onClick={() => handleNavigateTo('category/vr')} />
      <LastIn title={'Augmented Reality'} data={dataAR} onClick={() => handleNavigateTo('category/ar')} />
    </> : null;

  const dataForRoutes = [
    { path: "category/vr", title: 'Virtual Reality', data: dataVR },
    { path: "category/ar", title: 'Augmented Reality', data: dataAR, className: 'small' },
    { path: "category/mr", title: 'Mixed Reality', data: dataMR },
    { path: "category/web", title: 'Game Development', data: dataWebsite },
    { path: "category/blockchain-nft", title: 'Blockchain/ NFT', data: dataBlockchainNFT, className: 'small' },
    { path: "category/hardware", title: 'Hardware', data: dataHardware },
  ];

  const renderRoutes = dataForRoutes
    .map(route => <Route key={route.title} path={route.path} element={<BlogCategory title={route.title} data={route.data} className={route.className} />} />)

  return (
    <div className="listArticles">
      <Routes>
        {renderRoutes}
      </Routes>
      {renderBlogListArticles}
    </div>
  );
}

export default ListArticles;
