import './MailAndPhone.scss';

const MailAndPhone = () => {

    return (
        <div className='mailAndPhone'>
            <p className='mailAndPhone__p-sm'>E-mail</p>
            <p className='mailAndPhone__p-lg mail'><a style={{ color: 'inherit', textDecoration: 'inherit' }} href="mailto:hello@curiouselement.com">hello@curiouselement.com</a></p>
            <p className='mailAndPhone__p-sm'>Phone</p>
            <p className='mailAndPhone__p-lg'><a style={{ color: 'inherit', textDecoration: 'inherit' }} href="tel:+48 601635615">+48 601 635 615</a></p>
        </div>
    );
}

export default MailAndPhone;
