import './Checkbox.scss';

const Checkbox = ({ data, onChange, name, txt, spanTxt, required }) => {
    return (
        <div className='checkbox'>
            <input
                className='checkbox__input'
                type='checkbox'
                id={name}
                name={name}
                checked={data}
                onChange={onChange}
                required={required}
            />
            <label className='checkbox__label' htmlFor={name}><span className='checkbox__span'>{spanTxt}</span>{txt}</label>
        </div>
    );
}

export default Checkbox;
