import { useEffect, useState } from 'react';

import './ProjectCard.scss';

const ProjectCard = (props) => {

    const [isSmallImg, setIsSmallImg] = useState(false);

    const setImg = () => window.matchMedia("(max-width: 768px)").matches ? setIsSmallImg(true) : setIsSmallImg(false);

    useEffect(() => {
        setImg();
        window.addEventListener("resize", setImg);
        return () => window.removeEventListener("resize", setImg);
    }, [isSmallImg]);

    // const { img, smallImg, imgAlt, service, title, description } = props;
    // const renderSmallImg = isSmallImg ? smallImg : img;

    const { img, imgAlt, service, title, description } = props;
    const styles = { backgroundImage: `url(${img})` };

    const renderImage = !isSmallImg ?
        <div className="projectCard__wrap-img" style={styles}>
            <img className="projectCard__img" src={img} alt={imgAlt} />
        </div> : <div className="projectCard__wrap-img" style={styles}></div>

    return (
        <div className="projectCard">
            {renderImage}
            <p className="projectCard__service">{service}</p>
            <h2 className="projectCard__title">{title}</h2>
            <p className="projectCard__description">{description}</p>
        </div>
    );
}

export default ProjectCard;
