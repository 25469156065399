export const media = {
  onlyVeryTinyMobile: "(max-width: 360px)",
  onlyTinyMobile: "(max-width: 420px)",
  onlySmallMobile: "(max-width: 564px)",
  onlyMobile: "(max-width: 767px)",
  onlyTablet: "(min-width: 768px) and (max-width: 1279px)",
  onlyDesktop: "(min-width: 1280px)",
  onlySmallDesktop: "(min-width: 1280px) and (max-width: 1669px)",
  exceptMobile: "(min-width: 768px)",
  exceptTabletPortrait: "(max-width: 840px)",
  exceptTablet: "(max-width: 1024px)",
  exceptDesktop: "(max-width: 1279px)",
};