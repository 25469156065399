import { useEffect, forwardRef, useContext } from 'react';
import { AppContext } from '../../../../AppContext';

// components:
import Line from '../../../../components/Line/Line';
import Person from '../../../../components/Person/Person';

// data:
import { txtForMeetOurTeam } from '../../../../constants';

import './MeetOurTeam.scss';

const MeetOurTeam = forwardRef(({ height }, ref) => {

  const loadAnimationMeetOurTeam = useContext(AppContext).loadAnimationMeetOurTeam;
  const setLoadAnimationMeetOurTeam = useContext(AppContext).setLoadAnimationMeetOurTeam;

  // height for lines:
  const setHeightForLines = () => {
    const heightForLines = document.querySelector('.landingPage__bgc_dark').getBoundingClientRect().height;
    document.querySelector('.meetOurTeam__wrap-line').style.height = `${heightForLines}px`;
  };

  // width for lines:
  const setWidthForLines = () => {
    const widthForLines = document.querySelector('.meetOurTeam__team').getBoundingClientRect().width;
    document.querySelector('.meetOurTeam__wrap-line').style.width = `${widthForLines}px`;
  };

  // animation for lines and img:
  const animationAfterScroll = () => {
    if (window.scrollY > height.height1 - 200) setLoadAnimationMeetOurTeam((prevState) => ({
      ...prevState,
      line1: true
    }));
    if (window.scrollY > height.height2 - 800) setLoadAnimationMeetOurTeam((prevState) => ({
      ...prevState,
      line2: true
    }));
    if (window.scrollY > height.height3 - 800) setLoadAnimationMeetOurTeam((prevState) => ({
      ...prevState,
      line3: true
    }));
    if (window.scrollY > height.height1 - 600) setLoadAnimationMeetOurTeam((prevState) => ({
      ...prevState,
      personRow1: true
    }));
    if (window.scrollY > height.height3 - 400) setLoadAnimationMeetOurTeam((prevState) => ({
      ...prevState,
      personRow2: true
    }));
  };

  useEffect(() => {
    if (height.height1 === 0) return;

    setHeightForLines();
    window.addEventListener('resize', setWidthForLines);
    window.addEventListener('scroll', animationAfterScroll);

    return () => {
      window.removeEventListener('resize', setWidthForLines);
      window.removeEventListener('scroll', animationAfterScroll);
    };
  }, [height, loadAnimationMeetOurTeam]);

  const renderPerson = txtForMeetOurTeam
    .map((item, index) => <Person
        key={item.name}
        scrollAnimationParam={[0,1].includes(index) ? loadAnimationMeetOurTeam.personRow1 : loadAnimationMeetOurTeam.personRow2}
        img={item.img}
        name={item.name}
        profession={item.profession}
        email={item.email}
        linkedIn={item.linkedIn}
      />);

  return (
    <section className='meetOurTeam' ref={ref}>
      <div className='meetOurTeam__title'>
        <h1 className='meetOurTeam__h1'>Meet our team</h1>
        <p className='meetOurTeam__p'>We are a diverse team of domain experts and problem solvers.</p>
      </div>
      <div className='meetOurTeam__wrap-team'>
        <div className='meetOurTeam__team'>
          {renderPerson}
        </div>
        <div className='meetOurTeam__wrap-line'>
          <Line className='meetOurTeam' active={loadAnimationMeetOurTeam.line1} />
          <Line className='meetOurTeam' active={loadAnimationMeetOurTeam.line2} />
          <Line className='meetOurTeam' active={loadAnimationMeetOurTeam.line3} />
        </div>
      </div>
    </section>
  );
});

export default MeetOurTeam;
