import play_icon_dark from '../../svg/read_more_play_dark.svg';
import play_icon_FFF from '../../svg/read_more_play_FFF.svg';

import { useNavigate } from "react-router-dom";

import './ReadMore.scss';

const ReadMore = ({ dark, displayTxt, href }) => {
    const navigate = useNavigate();

    const renderPlayIconColor = dark ? play_icon_dark : play_icon_FFF;
    const renderTxTColor = dark ? 'dark' : '';
    const renderTxt = displayTxt ? <p className='readMore__txt'>read more</p> : null;

    return (
        <button className={`readMore__wrap ${renderTxTColor}`} onClick={() => navigate(href)}>
            {renderTxt}
            {/* <p className='readMore__txt'>read more</p> */}
            <div className='readMore__wrap-play-icon'>
                <img className='readMore__play-icon' src={renderPlayIconColor} alt='play icon' />
            </div>

        </button>
    );
}

export default ReadMore;
