import './Radio.scss';

const Radio = ({ checked, onChange, value, txt }) => {

    const renderRadio = value.map(item =>
        <div key={item} className='radio__wrap-input'>
            <input
                className='radio__input'
                type='radio'
                id={item}
                name={item}
                value={item}
                checked={checked === item}
                onChange={onChange} />
            <label className='radio__label' htmlFor={item}>{item}</label>
        </div>)

    return (
        <div className='radio__wrap'>
            <p className='radio__p'>{txt}</p>
            {renderRadio}
        </div>
    );
}

export default Radio;
