// components:
import ArticlesDate from '../ArticlesDate/ArticleDate';
import ReadMore from '../ReadMore/ReadMore';

import './HeaderBlog.scss';

const HeaderBlog = ({ service, date, txtForH1, txtForDescription, image, readMoreHref }) => {
    const imageStyle = {
        backgroundImage: `url(${image})`
    };

    return (
        <header className="headerBlog" style={imageStyle}>
            <div className="headerBlog__wrap">
                <ArticlesDate service={service} date={date} place={'header'} />
                <h1 className="headerBlog__h1">{txtForH1}</h1>
                <p className="headerBlog__description">{txtForDescription}</p>
                <ReadMore displayTxt={true} href={readMoreHref} />
            </div>
        </header>
    );
}

export default HeaderBlog;
