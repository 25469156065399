import './Person.scss';

const Person = ({ scrollAnimationParam, img, name, profession, email, linkedIn }) => {
  return (
    <div className={`person__person ${scrollAnimationParam ? 'active' : ''}`}>
      <div className='person__wrap-img'>
        <img className='person__img' src={img} alt={name} />
      </div>
      <p className='person__name'>{name}</p>
      <p className='person__profession'>{profession}</p>
      <p className='person__links'>
        <a href={`mailto:${email}`} target='_blank' rel="noreferrer">@</a>
        <a href={linkedIn} target='_blank' rel="noreferrer">
          <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21.7418 2.00014H4.35191C4.15643 1.9975 3.96233 2.03239 3.7807 2.10282C3.59906 2.17326 3.43345 2.27786 3.29332 2.41065C3.15319 2.54344 3.04128 2.70182 2.96399 2.87675C2.8867 3.05167 2.84554 3.23972 2.84286 3.43014V20.5701C2.84554 20.7606 2.8867 20.9486 2.96399 21.1235C3.04128 21.2985 3.15319 21.4568 3.29332 21.5896C3.43345 21.7224 3.59906 21.827 3.7807 21.8975C3.96233 21.9679 4.15643 22.0028 4.35191 22.0001H21.7418C21.9373 22.0028 22.1314 21.9679 22.3131 21.8975C22.4947 21.827 22.6603 21.7224 22.8004 21.5896C22.9406 21.4568 23.0525 21.2985 23.1298 21.1235C23.2071 20.9486 23.2482 20.7606 23.2509 20.5701V3.43014C23.2482 3.23972 23.2071 3.05167 23.1298 2.87675C23.0525 2.70182 22.9406 2.54344 22.8004 2.41065C22.6603 2.27786 22.4947 2.17326 22.3131 2.10282C22.1314 2.03239 21.9373 1.9975 21.7418 2.00014ZM9.03303 18.7401H5.95334V9.74014H9.03303V18.7401ZM7.49318 8.48014C7.06846 8.48014 6.66113 8.31578 6.3608 8.02323C6.06047 7.73067 5.89175 7.33388 5.89175 6.92014C5.89175 6.5064 6.06047 6.10961 6.3608 5.81705C6.66113 5.5245 7.06846 5.36014 7.49318 5.36014C7.71872 5.33522 7.9471 5.35699 8.1634 5.42402C8.3797 5.49105 8.57902 5.60183 8.74832 5.7491C8.91761 5.89637 9.05307 6.07682 9.14581 6.27862C9.23856 6.48043 9.2865 6.69904 9.2865 6.92014C9.2865 7.14124 9.23856 7.35985 9.14581 7.56166C9.05307 7.76346 8.91761 7.94391 8.74832 8.09118C8.57902 8.23845 8.3797 8.34923 8.1634 8.41626C7.9471 8.48329 7.71872 8.50505 7.49318 8.48014ZM20.1404 18.7401H17.0607V13.9101C17.0607 12.7001 16.6193 11.9101 15.5004 11.9101C15.1541 11.9126 14.8169 12.0184 14.5342 12.2133C14.2515 12.4082 14.037 12.6828 13.9195 13.0001C13.8391 13.2352 13.8043 13.4827 13.8168 13.7301V18.7301H10.7371C10.7371 18.7301 10.7371 10.5501 10.7371 9.73014H13.8168V11.0001C14.0966 10.5272 14.5035 10.1377 14.9937 9.87334C15.4839 9.60902 16.0389 9.47999 16.5988 9.50014C18.6519 9.50014 20.1404 10.7901 20.1404 13.5601V18.7401Z" fill="#ffffff"/>
          </svg>
        </a>
      </p>
    </div>
  );
}

export default Person;
