import './Button.scss';

const Button = ({ txt, svg, onClick, dataName, disabled, className }) => {
    return (
        <button
            className={`button ${className || ''}`}
            onClick={onClick}
            data-name={dataName}
            disabled={disabled}>
            {txt}
            {svg ? <img className="button__svg" src={svg} alt='arrow' /> : null}
        </button>
    );
}

export default Button;