import { useNavigate } from "react-router-dom";

import { txtForSocialMedia, txtForOfficeInfo } from '../../constants';

// components:
import Button from '../Button/Button';
import MailAndPhone from '../MailAndPhone/MailAndPhone';

import arrow from '../../svg/lets_talk_arrow.svg';

import './LetsTalk.scss';

const LetsTalk = () => {

  const estimatePath = '/contact';
  const navigate = useNavigate();

  const renderSocialMediaLinks =
    txtForSocialMedia
      .map(link =>
        <li key={link.name} className={`letsTalk__wrap ${link.name}`}>
          <a href={link.href} className="letsTalk__a">
            <img className="letsTalk__icon" src={link.icon} alt={`${link.name} icon`} />
            <p className="letsTalk__p" href={link.href}>{link.name}</p>
          </a>
        </li>);

  const renderOfficeInfo = txtForOfficeInfo
    .map(txt => <p key={txt} className='letsTalk__p'>{txt}</p>);

  const renderAddresses = txtForOfficeInfo
    .map((item, index) =>
      <div key={index}>
        {item.map((child, ii) =>
          <p key={ii} className='letsTalk__p'>{child}</p>
        )}
      </div>
    )

  const handleGoToEstimate = () => navigate(estimatePath);

  return (
    <section className='letsTalk'>
      <div className='letsTalk__wrap-h1'>
        <h1 className='letsTalk__h1'>let’s talk</h1>
        <Button txt={'Contact us'} svg={arrow} onClick={handleGoToEstimate} />
      </div>
      <div className='letsTalk__wrap-contact'>
        <div className="letsTalk__contact-firstColumn">
          <MailAndPhone />
          <div className='letsTalk__wrap-office'>
            <h3 className='letsTalk__h3'>Office</h3>
            <div className="letsTalk__offices">
              {renderAddresses}
            </div>
          </div>
        </div>
        <div className='letsTalk__wrap-social-media'>
          <h3 className='letsTalk__h3'>Social media</h3>
          <ul className='letsTalk__ul'>
            {renderSocialMediaLinks}
          </ul>
        </div>
      </div>
    </section>
  );
}

export default LetsTalk;
