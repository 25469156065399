import { useState } from 'react';

// components:
import Button from '../../../components/Button/Button';

// data:
import { txtForEstimateFirstQuestion, txtForEstimateSecondQuestion } from '../../../constants';

// svg:
import arrow from '../../../svg/lets_talk_arrow.svg';
import checkedIcon from '../../../svg/contact_estimate_checked.svg';

// react-bootstrap:
import Accordion from 'react-bootstrap/Accordion';

import './EstimateAccordion.scss';

const EstimateAccordion = ({ handleClickRectangle, estimate, setDisplayContactForm }) => {

  const EVENT_KEY_FOR_FIRST_ACCORDION_ITEM = '0';
  const EVENT_KEY_FOR_SECOND_ACCORDION_ITEM = '1';
  const EVENT_KEY_FOR_THIRD_ACCORDION_ITEM = '2';

  const isFirstBtnDisabled = estimate.firstAnswer.length === 0;
  const isSecondBtnDisabled = estimate.secondAnswer.length === 0;
  const isThirdBtnDisabled = estimate.thirdAnswer.length === 0;

  const isFirstAccordioItemChecked = estimate.firstAnswer.length !== 0;
  const isSecondAccordioItemChecked = estimate.secondAnswer.length !== 0;
  const isThirdAccordioItemChecked = estimate.thirdAnswer.length !== 0;

  const renderNumberOrCheckedIcon = (accordionItemChecked, txtNumber) => <p className={`contactAndEstimate__number ${accordionItemChecked ? 'active' : ''}`}>{accordionItemChecked ? <img className='contactAndEstimate__checked-icon' src={checkedIcon} alt='checked icon' /> : txtNumber}</p>

  const [openAccordionItem, setOpenAccordionItem] = useState(EVENT_KEY_FOR_FIRST_ACCORDION_ITEM);

  const renderQuestions = (data, keyname) => (
    data.map(item => <div
      key={item}
      className='contactAndEstimate__rectangle'
      data-keyname={keyname}
      onClick={handleClickRectangle}>{item}</div>));

  const handleOpenAccordionItem = (e) => {
    const actuallyEventName = e.currentTarget.dataset.name === undefined ? e.currentTarget.parentNode.dataset.name : e.currentTarget.dataset.name;
    if (estimate.firstAnswer.length === 0) return;
    if (actuallyEventName === EVENT_KEY_FOR_THIRD_ACCORDION_ITEM && estimate.secondAnswer.length === 0) return;
    setOpenAccordionItem(actuallyEventName);
  };

  const handleCompleteApplication = () => setDisplayContactForm(true);

  return (
    <Accordion activeKey={openAccordionItem} >
      <Accordion.Item eventKey={EVENT_KEY_FOR_FIRST_ACCORDION_ITEM}>
        <Accordion.Header
          onClick={handleOpenAccordionItem}
          data-name={EVENT_KEY_FOR_FIRST_ACCORDION_ITEM}
          data-disabled={isFirstBtnDisabled}>
          {renderNumberOrCheckedIcon(isFirstAccordioItemChecked, ' ')}
          <p className='contactAndEstimate__question'>What would you like to do?</p>
        </Accordion.Header >
        <Accordion.Body>
          <div className='contactAndEstimate__wrap-rectangles'>
            {renderQuestions(txtForEstimateFirstQuestion, 'firstAnswer')}
          </div>
          {/* <Button
                        //data-name - set active event key
                        dataName={EVENT_KEY_FOR_SECOND_ACCORDION_ITEM}
                        txt={'next'}
                        svg={arrow}
                        onClick={handleOpenAccordionItem}
                        disabled={isFirstBtnDisabled} /> */}
          <Button
            txt={'Complete your application'}
            svg={arrow}
            disabled={isFirstBtnDisabled}
            onClick={handleCompleteApplication} />
        </Accordion.Body>
      </Accordion.Item>
      {/* <Accordion.Item eventKey={EVENT_KEY_FOR_SECOND_ACCORDION_ITEM}>
        <Accordion.Header
          onClick={handleOpenAccordionItem}
          data-name={EVENT_KEY_FOR_SECOND_ACCORDION_ITEM}
          data-disabled={isFirstBtnDisabled}>
          {renderNumberOrCheckedIcon(isSecondAccordioItemChecked, '2/3')}
          <p className='contactAndEstimate__question'>What is the scope of your project?</p>
        </Accordion.Header>
        <Accordion.Body>
          <div className='contactAndEstimate__wrap-rectangles'>
            {renderQuestions(txtForEstimateSecondQuestion, 'secondAnswer')}
          </div>
          <Button
            //data-name - set active event key
            dataName={EVENT_KEY_FOR_THIRD_ACCORDION_ITEM}
            txt={'next'}
            svg={arrow}
            onClick={handleOpenAccordionItem}
            disabled={isSecondBtnDisabled}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey={EVENT_KEY_FOR_THIRD_ACCORDION_ITEM}>
        <Accordion.Header
          onClick={handleOpenAccordionItem}
          data-name={EVENT_KEY_FOR_THIRD_ACCORDION_ITEM}
          data-disabled={isSecondBtnDisabled || isFirstBtnDisabled}>
          {renderNumberOrCheckedIcon(isThirdAccordioItemChecked, '3/3')}
          <p className='contactAndEstimate__question'>How did you hear about us?</p>
        </Accordion.Header >
        <Accordion.Body>
          <div className='contactAndEstimate__wrap-rectangles'>
            {renderQuestions(txtForEstimateSecondQuestion, 'thirdAnswer')}
          </div>
          <Button
            txt={'Complete your application'}
            svg={arrow}
            disabled={isThirdBtnDisabled}
            onClick={handleCompleteApplication} />
        </Accordion.Body>
      </Accordion.Item> */}
    </Accordion>
  );
}

export default EstimateAccordion;
