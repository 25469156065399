import './SliderIndicators.scss';

const SliderIndicators = (props) => {
    return (
        <button data-id={props.id} className={`sliderIndicators__circle ${props.className}`} onClick={props.onClick}>
            <svg viewBox="0 0 52 52" className="sliderIndicators__circle-back">
                <circle cx="26" cy="26" r="25" />
            </svg >
            <svg viewBox="0 0 52 52" className="sliderIndicators__circle-front">
                <circle cx="26" cy="26" r="25" />
            </svg>
            <span className="sliderIndicators__dot" />
        </button>
    );
};

export default SliderIndicators;
