import './Line.scss';

const Line = ({ className, active }) => {

    return (
        <div className={`${className}__line line`}>
            <div className={`${className}__active-line ${active ? 'active' : ''}`}></div>
        </div>
    );
};

export default Line;


