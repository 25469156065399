import { useState, forwardRef, useEffect } from 'react';

// data:
import { txtForOurTechnology } from '../../../constants';
import useMedia from '../../../hooks/useMedia';

import './OurTechnology.scss';

const OurTechnology = forwardRef(({ height }, ref) => {
  const [activeElementIndex, setActiveElementIndex] = useState(2);
  const [marginForTools, setMarginForTools] = useState(0);
  // const [loadAnimationOurTech, setLoadAnimationOurTech] = useState(false);

  const { isMobile } = useMedia();
  const [activeMobileIndex, setActiveMobileIndex] = useState(0);
  const [opened, setOpen] = useState(false);

  const getElementsFromTechnologySection = (dataArr, arrForElements, selector) => {
    dataArr.forEach((item, index) => {
      arrForElements.push([...document.querySelectorAll(selector)].find(elem => elem.id === `${index}`));
    });
  }

  useEffect(()=>{
  if (height === 0 || isMobile) return;
    const toolsArray = [];
    const technologyArray = [];

    getElementsFromTechnologySection(txtForOurTechnology, toolsArray, '.ourTechnology__tools');
    getElementsFromTechnologySection(txtForOurTechnology, technologyArray, '.ourTechnology__technology');

      let technologyColumnHeight = 0;
      let toolsColumnHeight = 0;
      let marginToAdd = 0;
      for (let i = 0; i < activeElementIndex; i++) {
        technologyColumnHeight = technologyColumnHeight + technologyArray[i].getBoundingClientRect().height;
        toolsColumnHeight = toolsColumnHeight + toolsArray[i].getBoundingClientRect().height;
      }
      technologyColumnHeight = technologyColumnHeight + (technologyArray[activeElementIndex].getBoundingClientRect().height)/2;
      toolsColumnHeight = toolsColumnHeight + (toolsArray[activeElementIndex].getBoundingClientRect().height)/2;
      marginToAdd += (technologyColumnHeight - toolsColumnHeight);
      const wrapTools = document.querySelector('.ourTechnology__wrap-tools');
      if (!wrapTools) return;
      wrapTools.style.marginTop = `${marginToAdd}px`;
  },[height, activeElementIndex, isMobile]);

  const renderTechnology = (group, isId) => txtForOurTechnology
    .map((txt, index) => <p
      key={txt[group]}
      onClick={group === 'technology' ? ()=>{setActiveElementIndex(index)} : undefined}
      id={isId ? index : null}
      data-id={!isId ? index : null}
      className={index === activeElementIndex ? `ourTechnology__${group} active` : `ourTechnology__${group}`}
    > {txt[group]}</p >);

  const toggleOpen = (isActive) => {
    if (isActive && opened) setOpen(false);
    else setOpen(true);
  }

  return (
    <section className="ourTechnology" ref={ref}>
      <h1 className="ourTechnology__h1">Our technology</h1>
      {isMobile ?
        <div className='ourTechnology__mobile_list'>
          {txtForOurTechnology.map((item, index) => (
            <div key={index} onClick={() => { setActiveMobileIndex(index); toggleOpen(index === activeMobileIndex); }} className={`item_wraper${(index === activeMobileIndex && opened) ? ' active' : ''}`}>
              <h3>{item.technology}</h3>
              <p className={`item_para${(index === activeMobileIndex && opened) ? ' active' : ''}`}>{item.tools}</p>
            </div>
          ))}
        </div>
        :
        <>
          <div className="ourTechnology__wrap">
            <div className="ourTechnology__wrap-tech">
              {renderTechnology('technology', true)}
            </div>
            <div className="ourTechnology__wrap-tools">
              {renderTechnology('tools', true)}
            </div>
          </div>
          <div className='ourTechnology__line'>
            <div className='ourTechnology__active-line'></div>
          </div>
          <div className='ourTechnology__line'>
            <div className='ourTechnology__active-line'></div>
          </div>
        </>
      }
    </section>
  );
});

export default OurTechnology;
